import { FC } from "react";
import { Loading, ShowActionsProps, TopToolbar } from "react-admin";
import { Delivery } from "../../types";
import { deliveryActions } from "./util";
import AssignDriverDialog from "./actions/AssignDriverDialog";
import EndDeliveryDialog from "./actions/EndDeliveryDialog";
import StartDeliveryDialog from "./actions/StartDeliveryDialog";
import { deliveryCan } from "./util";
import CannotPickDialog from "./actions/CannotPickDialog";

const DeliveryActions: FC<ShowActionsProps> = (props) => {
  const { data } = props;
  const model = data as Delivery;
  // const history = useHistory();

  if (!model) {
    return <Loading />;
  }

  return (
    <TopToolbar>
      {deliveryCan(model, deliveryActions.ASSIGN) && (
        <AssignDriverDialog record={model} />
      )}
      {deliveryCan(model, deliveryActions.START) && (
        <StartDeliveryDialog record={model} />
      )}
      {deliveryCan(model, deliveryActions.FINISH) && (
        <EndDeliveryDialog record={model} />
      )}
      {deliveryCan(model, deliveryActions.CANNOT_START) && (
        <CannotPickDialog record={model} />
      )}
    </TopToolbar>
  );
};

export default DeliveryActions;
