import { formatISO9075 } from "date-fns/esm";
import { get } from "lodash";
import React from "react";
import { Delivery, Location } from "../../types";
import { convertIsoToLocale } from "../../utils/date";
import { LocationContext } from "../contexts";
import { DeliveryIdField } from "../fields";
import { FieldProps } from "../fields/types";
import MyCard from "./MyCard";

const createDeliveryString = (
  originalLocationName: string,
  locations: Location[],
  locationId: number | undefined,
  isoString: string | undefined
) => {
  if (!locationId) {
    return originalLocationName;
  }
  const found = locations.find((item) => item.id === locationId);
  if (!found) {
    return "";
  }

  if (!isoString) {
    return `${found.name}`;
  }

  const timeString = convertIsoToLocale(isoString);
  return `${found.name}, ${timeString}`;
};

const createPickedUpString = (delivery: Delivery, locations: Location[]) =>
  createDeliveryString(
    delivery.product_instance.location.name,
    locations,
    delivery.picked_location_id,
    delivery.picked_time
  );

const createDropOffString = (delivery: Delivery, locations: Location[]) =>
  createDeliveryString(
    delivery.target_location.name,
    locations,
    delivery.delivered_location_id,
    delivery.delivered_time
  );

const DeliveryCard: React.FC<FieldProps> = (props) => {
  const { record, source } = props;
  const delivery: Delivery = source === "id" ? record : get(record, source);
  const { locations } = React.useContext(LocationContext);
  const from = createPickedUpString(delivery, locations);
  const to = createDropOffString(delivery, locations);

  if (!delivery) return <></>;

  return (
    <MyCard
      title="Delivery"
      status={<DeliveryIdField record={delivery} />}
      content={[
        {
          label: "date",
          value: formatISO9075(new Date(delivery.target_delivery_time)),
        },
        { label: "Driver", value: delivery.driver?.full_name ?? "" },
        { label: "From", value: from },
        { label: "To", value: to },
      ]}
    />
  );
};

DeliveryCard.defaultProps = {
  label: "Delivery",
  addLabel: true,
};

export default DeliveryCard;
