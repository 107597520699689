import { Delivery } from "../../types";
import { foundIn } from "../../utils";

export const deliveryStatus = {
  NEW: "new",
  ASSIGNED: "driver_assigned",
  PICKED: "picked",
  DELIVERED: "delivered",
  CANCELLED: "cancelled",
};

/**
 * Check if action can be done when delivery status in a certain state
 *
 * @param delivery
 * @param action
 * @returns
 */
export const deliveryCan = (delivery: Delivery, action: string): boolean => {
  switch (action) {
    case deliveryActions.ASSIGN:
      return foundIn(delivery.status, [
        deliveryStatus.NEW,
        deliveryStatus.ASSIGNED,
      ]);
    case deliveryActions.START:
      return foundIn(delivery.status, [deliveryStatus.ASSIGNED]);
    case deliveryActions.FINISH:
      return foundIn(delivery.status, [deliveryStatus.PICKED]);
    case deliveryActions.CANNOT_START:
      return foundIn(delivery.status, [deliveryStatus.ASSIGNED]);
    default:
      return false;
  }
};

export const deliveryActions = {
  ASSIGN: "assign",
  START: "start",
  FINISH: "end",
  CANCEL: "cancel",
  CANNOT_START: "cannot_start",
  BULK_ASSIGN: "bulk_assign",
};

export const deliveryTypes = {
  COLLECTION: "collection",
};
