import React from "react";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

const AllocationFilter: React.FC<any> = (props) => {
  const { onChange } = props;

  return (
    <Box>
      <FormControl fullWidth>
        {/* <InputLabel htmlFor="">Type</InputLabel> */}
        <Select
          native
          onChange={onChange}
          inputProps={{
            name: "type",
            id: "",
          }}
        >
          <option value="">All</option>
          <option value="implant">Implant</option>
          <option value="instrument">Instrument</option>
          <option value="other">Additional/Others</option>
        </Select>
      </FormControl>
    </Box>
  );
};

export default AllocationFilter;
