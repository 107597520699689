import {
  Box,
  Button,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import React from "react";
import { AllocationChip, DeliveryChip } from "../../../components/Chip";
import { ProductInstanceIdField } from "../../../components/fields";
import { Allocation, ProductInstance } from "../../../types";
import useStyles from "./styles";

interface Props {
  allocation: Allocation;
  onRemoveAllocation?: (allocation: ProductInstance) => void;
}
const AllocationItem: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { allocation, onRemoveAllocation } = props;
  const { product_instance: instance } = allocation;
  console.log("instance :>> ", instance);

  if (!instance) {
    return (
      <ListItem>
        Allocation ID {allocation.id} does not have product instance.
      </ListItem>
    );
  }

  return (
    // <ListItem key={allocation.id} button className={classes.listItem}>
    <Box className={classes.listItem}>
      <ListItemText
        secondary={
          <Box>
            <Box style={{ marginBottom: 10 }}>
              <ProductInstanceIdField record={instance} />
            </Box>
            <Box>Current Location: {instance.location.name}</Box>
            <Box>Product: {instance.product.name}</Box>
            <Box>Type: {instance.product.type}</Box>
            <Box
              className={classes.chips}
              style={{
                borderTop: "1px solid #eee",
                marginTop: 10,
                paddingTop: 10,
              }}
            >
              <AllocationChip resource={allocation} />
              {allocation?.delivery && (
                <DeliveryChip resource={allocation.delivery} />
              )}
            </Box>
          </Box>
        }
      />

      {/* Show Delete button if the instance is IN the allocations array */}
      {onRemoveAllocation && (
        <ListItemSecondaryAction>
          <Button
            variant="contained"
            size="small"
            onClick={() => onRemoveAllocation(instance)}
            className={classes.buttonDanger}
          >
            Remove
          </Button>
        </ListItemSecondaryAction>
      )}
    </Box>
    // </ListItem>
  );
};

export default AllocationItem;
