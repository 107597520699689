import { capitalize } from "lodash";
import React from "react";
import TitleBreadcrumb from "./TitleBreadcrumb";

interface Props {
  resourceName: string;
  resourceId?: string;
  mode: "show" | "create" | "edit" | "revise";
}

const CrudBreadcrumb: React.FC<Props> = ({
  resourceName,
  resourceId,
  mode,
}) => {
  let displayTitle = "";

  switch (resourceName) {
    case "hospital-rooms":
      displayTitle = "Department";
      break;
    case "product-instances":
      displayTitle = "Product Sets";
      break;
    default:
      displayTitle = capitalize(resourceName);
  }

  const breadcrumb = [
    {
      link: `/${resourceName}`,
      title: `${displayTitle}`,
    },
    {
      link: `/${resourceName}/${resourceId ? resourceId + "/" : ""}${mode}`,
      title: capitalize(`${mode} ${resourceId ?? ""}`),
    },
  ];

  return <TitleBreadcrumb paths={breadcrumb} />;
};

export default CrudBreadcrumb;
