import React from "react";
import { Allocation } from "../../../types";
import { AllocationActions } from "../util";
import ActionProps from "./AllocationActionProps";
import ConfirmationButton from "./ConfirmationButton";

const FinishButton: React.FC<ActionProps<Allocation>> = (props) => {
  const { data: allocation } = props;

  const dialogContent = `Finish the allocation. The Box can be assigned to another allocation`;

  return (
    <ConfirmationButton
      data={allocation}
      action={AllocationActions.FINISH}
      buttonText={`Finish`}
      dialogTitle={`Finish the Allocation`}
      dialogContent={dialogContent}
      confirmationButtonText={`Confirm`}
    />
  );
};

export default FinishButton;
