import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import {
  DeliveryIdField,
  ProductInstanceIdField,
} from "../../components/fields";
import { Booking, BookingItem } from "../../types";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    padding: "1rem",
  },
});

const BookingItems: React.FC<{ record?: Booking }> = ({ record }) => {
  const classes = useStyles();

  if (!record) return <>Loading...</>;
  const { items } = record;
  items.sort(
    (a, b) =>
      a.product.type.localeCompare(b.product.type) ||
      a.product.name.localeCompare(b.product.name)
  );

  const getAllocations = (item: BookingItem, booking: Booking) => {
    return booking.allocations.filter(
      (allocation) => allocation.product_id === item.product_id
    );
  };

  const getDeliveries = (item: BookingItem, booking: Booking) => {
    return booking.deliveries.filter(
      (delivery) => delivery.product_instance.product_id === item.product_id
    );
  };

  return (
    <TableContainer style={{ margin: "2rem auto" }}>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>Product</TableCell>
            <TableCell>Qty</TableCell>
            <TableCell>Allocations</TableCell>
            <TableCell>Deliveries</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.product.type}</TableCell>
              <TableCell>{item.product.name}</TableCell>
              <TableCell>{item.quantity}</TableCell>
              <TableCell>
                {getAllocations(item, record).map((allocation) => (
                  <ProductInstanceIdField
                    record={allocation}
                    source="product_instance"
                  />
                ))}
              </TableCell>
              <TableCell>
                {getDeliveries(item, record).map((delivery) => (
                  <DeliveryIdField record={delivery} />
                ))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default BookingItems;
