import { get } from "lodash";
import React from "react";
import { FieldProps } from "./types";

interface Props extends FieldProps {
  hospitalSource: string;
}

const HospitalRoomField: React.FC<Props> = (props) => {
  const { record, source, hospitalSource } = props;
  if (!record) {
    return <span>-</span>;
  }

  return (
    <span>
      {get(record, hospitalSource, "-")} ({get(record, source, "-")})
    </span>
  );
};
HospitalRoomField.defaultProps = {
  label: "Venue",
  addLabel: true,
};

export default HospitalRoomField;
