import { Box, List } from "@material-ui/core";
import React from "react";
import { DeliveryCard } from "../../components/cards";
import { Booking, Delivery } from "../../types";

interface DeliveryProps {
  record?: Booking;
  //   deliveries: Delivery[];
}
const DeliveryContainer: React.FC<DeliveryProps> = (props) => {
  const { record: booking } = props;
  const [deliveries, setDeliveries] = React.useState<Delivery[]>([]);

  React.useEffect(() => {
    if (booking) {
      setDeliveries(booking.deliveries);
    }
  }, [booking]);

  if (deliveries.length === 0) {
    return (
      <Box display="flex" width="100%" height="100px" alignItems="center">
        <Box>There's no delivery assigned to this booking yet.</Box>
      </Box>
    );
  }

  return (
    <List
      style={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        width: "100%",
      }}
    >
      {deliveries.map((delivery: Delivery) => (
        <Box style={{ padding: 3 }}>
          <DeliveryCard record={delivery} source="id" />
        </Box>
      ))}
    </List>
  );
};

export default DeliveryContainer;
