import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import { makeStyles } from "@material-ui/core/styles";
import AttachmentIcon from "@material-ui/icons/Attachment";
import SendIcon from "@material-ui/icons/Send";
import { Message } from "@twilio/conversations/lib/message";
import { get } from "lodash";
import { DropzoneDialog } from "material-ui-dropzone";
import { useDataProvider, useQuery } from "ra-core";
import React, { SyntheticEvent, useRef } from "react";
import { MessageBox } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import { apiUrl, getUser, httpClient } from "../../dataProvider";
import useTwilio from "../../hooks/useTwilio";
import { Admin, Chat } from "../../types";
import "./Conversation.css";

const useStyles = makeStyles({
  root: {
    border: "1px solid #eee",
    padding: "2rem",
    borderRadius: "4px",
    boxShadow:
      "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
  },
});
interface Props {
  id: string; // Chat ID
}
const MyConversation: React.FC<Props> = (props) => {
  const dataProvider = useDataProvider();
  const userName = `User:${localStorage.getItem("me")}`;
  const [newMessage, setNewMessage] = React.useState("");
  const [isJoining, setIsJoining] = React.useState(false);
  const [isSendingDisabled, setIsSendingDisabled] = React.useState(true);
  const [conversationSid, setConversationSid] = React.useState<string>("");
  const [showUploadDialog, setShowUploadDialog] = React.useState(false);
  const classes = useStyles();

  /**
   * get chat token from the server
   */
  const getToken = () =>
    dataProvider
      .getOne<Admin>("admins", {
        id: localStorage.getItem("me") ?? 0,
      })
      .then(({ data }) => data.user.chat_jwt);

  const { status, messages, getUserName, selectedConversation, userList } =
    useTwilio({
      getToken,
      conversationSid,
    });
  /**
   * Enable/disable sending button.
   * If message.trim is empty, then disable the button.
   */
  React.useEffect(() => {
    if (newMessage.trim() !== "") {
      setIsSendingDisabled(false);
    } else {
      setIsSendingDisabled(true);
    }
  }, [newMessage]);

  /**
   * get the conversation channel from API server
   * get the conversation from twilio when client and chat are updated
   */
  const { data: chat }: { data?: Chat } = useQuery({
    type: "getOne",
    resource: "chats",
    payload: { id: props.id },
  });
  React.useEffect(() => {
    if (chat) {
      setConversationSid(chat.provider_ref_id);
    }
  }, [chat]);

  /**
   * Send new message.
   * Before sending a new message, we have to trim it first.
   */
  const sendMessage = () => {
    const messageToSend = newMessage.trim();
    selectedConversation
      ?.sendMessage(messageToSend)
      .then(() => setNewMessage(""));
  };

  /**
   * Joining a conversation
   * @returns void
   */
  const joinConversation = () => {
    if (!chat) {
      return;
    }
    setIsJoining(true);
    httpClient(`${apiUrl}/chats/${props.id}/participants`, {
      method: "post",
      ...getUser(),
    })
      .then(() => console.log("need to refresh the messages"))
      .finally(() => setIsJoining(false));
  };

  /**
   * Upload files/medias to Twilio
   * @param files
   */
  const handleSendFiles = (files: File[]) => {
    files.forEach((file) => {
      const formData = new FormData();
      formData.append("file", file);
      selectedConversation?.sendMessage(formData);
    });
    setShowUploadDialog(false);
  };

  return (
    <Box className={classes.root}>
      {status === "loading" && (
        <Box border="1px solid #ddd" minHeight="200px" textAlign="center">
          <Box>Loading conversation ...</Box>
          <Box>
            <CircularProgress />
          </Box>
        </Box>
      )}

      {status === "error" && (
        <Box marginY="1rem">
          <Box marginBottom="0.8rem">
            Cannot join chat room. Please click the button below to join
            manually.
          </Box>
          <Button
            onClick={joinConversation}
            variant="contained"
            color="primary"
            size="small"
            disabled={isJoining}
          >
            {isJoining ? "Joining..." : "Join Chat"}
          </Button>
        </Box>
      )}

      {status === "success" && userList.length > 0 && (
        <Box border="1px solid #ddd">
          <Box height="600px" style={{ overflowY: "scroll" }}>
            {messages.map((message) => {
              const position = message.author === userName ? "right" : "left";
              const title = getUserName(message.author);

              return (
                <MyMessageBox
                  key={message.index}
                  position={position}
                  message={message}
                  title={title}
                />
              );
            })}
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            borderTop="1px solid #ddd"
          >
            <Box marginX="1rem">
              <IconButton
                color="primary"
                onClick={() => setShowUploadDialog(true)}
              >
                <AttachmentIcon></AttachmentIcon>
              </IconButton>
            </Box>
            <Box width="80%">
              <Input
                className="input-form"
                placeholder={
                  status === "success" ? "Type here..." : "Loading..."
                }
                multiline={true}
                rowsMax={4}
                fullWidth
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setNewMessage(e.target.value)
                }
                onKeyPress={(event) => {
                  // if (event.key === "Enter" && !event.shiftKey) {
                  //   event.preventDefault();
                  //   sendMessage();
                  // }
                  if (event.shiftKey === false && event.key === "Enter") {
                    event.preventDefault();
                    sendMessage();
                  }
                }}
                value={newMessage}
                disabled={status !== "success"}
              />
            </Box>
            <Box marginX="1rem">
              <IconButton
                onClick={sendMessage}
                disabled={isSendingDisabled}
                color="primary"
              >
                <SendIcon></SendIcon>
              </IconButton>
            </Box>
          </Box>
        </Box>
      )}

      <DropzoneDialog
        open={showUploadDialog}
        acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
        showPreviews={true}
        maxFileSize={5000000}
        onClose={() => setShowUploadDialog(false)}
        onSave={handleSendFiles}
      />
    </Box>
  );
};

export default MyConversation;

interface MyMessageBoxProps {
  message: Message;
  title: string;
  position: "right" | "left";
}
const MyMessageBox: React.FC<MyMessageBoxProps> = (props) => {
  const { message, title, position } = props;
  const type = message.type === "text" ? "text" : "image";
  const [uri, setUri] = React.useState("");
  const date = message.dateCreated;
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    // @ts-ignore
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  React.useEffect(() => {
    if (message.type === "media") {
      // If the message is media, we need to get temporary URL from Twilio
      // https://www.twilio.com/docs/conversations/media-support-conversations
      message.media.getContentTemporaryUrl().then((url) => setUri(url));
    }
    scrollToBottom();
  }, [message]);

  const handleDownloadImage = (e: SyntheticEvent) => {
    const url = get(e, "target.src");
    if (url) {
      window.open(url);
    }
  };

  if (type === "image") {
    return (
      <MessageBox
        id={message.sid}
        position={position}
        type={"photo"}
        text={message.body ?? ""}
        date={date}
        title={title}
        data={{
          uri,
        }}
        onOpen={handleDownloadImage}
      />
    );
  }

  return (
    <div>
      <MessageBox
        id={message.sid}
        position={position}
        type={type}
        text={
          <div>
            {message.body.split("\n").map((x) => {
              return (
                <>
                  {x} <br />
                </>
              );
            })}
          </div>
        }
        date={date}
        title={title}
      />
      <div ref={messagesEndRef}></div>
    </div>
  );
};
