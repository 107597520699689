import { Box } from "@material-ui/core";
import { Labeled } from "ra-ui-materialui";
import React from "react";
import { Booking } from "../../types";
import { convertIsoToDateTime } from "../../utils/date";

const ReportItems: React.FC<{ record?: Booking }> = ({ record }) => {
  if (!record) return <>Loading...</>;
  const { reports } = record;

  if (reports.length === 0) {
    return (
      <Box display="flex" width="100%" height="100px" alignItems="center">
        <Box>There's no report uploaded to this booking yet.</Box>
      </Box>
    );
  }

  return (
    <Box mt={3}>
      {/* Rpeort Date  */}
      <Labeled label="Report Date">
        <span>
          {record.reports[0].created_at
            ? `${convertIsoToDateTime(record.reports[0].created_at).date}, ${
                convertIsoToDateTime(record.reports[0].created_at).time
              }`
            : "-"}
        </span>
      </Labeled>

      <Labeled label="Report Note">
        <span>{record.report_note ?? "-"}</span>
      </Labeled>

      <Labeled label="Attachments">
        <ul>
          {reports.map((report, counter) => (
            <li>
              <a
                href={report.url}
                target="_blank"
                rel="noreferrer"
              >{`Attachment ${counter + 1}`}</a>
            </li>
          ))}
        </ul>
      </Labeled>
    </Box>
  );
};
export default ReportItems;
