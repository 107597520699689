import { Box, Button, TextField } from "@material-ui/core";
import { getYear } from "date-fns";
import { useState } from "react";
import { fetchUtils, useLogin } from "react-admin";
import logo from "../../assets/images/logo.svg";
import "./auth.scss";

const LoginPage = ({ theme }: any) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [emailForgot, setEmailForgot] = useState("");
  const [isSuccessSentEmail, setIsSuccessSentEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorLogin, setIsErrorLogin] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const httpClient = fetchUtils.fetchJson;
  const login = useLogin();

  // action button for login
  const submitLogin = async (e: any) => {
    e.preventDefault();
    const credentials = {
      username: email,
      password: password,
    };
    setIsLoading(true);

    // try {
    //   const user = await Auth.signIn(email, password);
    //   setIsLoading(false);
    //   console.log("USER", user);
    // } catch (error) {
    //   setIsLoading(false);
    // }

    login(credentials).catch(() => {
      setIsLoading(false);
      setIsErrorLogin(true);
    });
  };
  // action link for forgot password
  const _onSubmitForgot = () => {
    setIsLoading(true);
    const body = {
      data: {
        email: emailForgot,
      },
    };
    // custom api and not use dataProvider,
    // cause dataProvider (create) return data.id
    httpClient(`${apiUrl}/auth/forget-password`, {
      method: "POST",
      body: JSON.stringify(body.data),
    })
      .then(() => {
        setIsSuccessSentEmail(true);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setIsSuccessSentEmail(true);
        setIsLoading(false);
      });
  };

  const onClickForgot = () => {
    setIsForgotPassword(true);
  };

  const BackToLogin = () => (
    <Box style={{ margin: "20px 0" }}>
      <Button
        variant="text"
        onClick={() => {
          setIsForgotPassword(false);
          setIsSuccessSentEmail(false);
        }}
      >
        Back to Login
      </Button>
    </Box>
  );

  return (
    <div className="Auth">
      {/* {!isForgotPassword && ( */}
      <div className="LoginForm">
        <div className="left">
          <img src={logo} style={{ width: "24%" }} alt="logo" />
        </div>
        <div className="right">
          <div className="container">
            {/* show login form */}
            {!isForgotPassword && (
              <form name="loginform" onSubmit={submitLogin}>
                <h2>Login to Healthcare Logistics Solutions.</h2>
                <TextField
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  label="Email"
                />
                <br />
                <br />
                <TextField
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                  label="Password"
                  type="password"
                />
                <Box style={{ marginTop: "20px" }}>
                  <Button
                    onClick={submitLogin}
                    color="primary"
                    variant="contained"
                    disabled={isLoading}
                  >
                    Login
                  </Button>
                </Box>
                {isErrorLogin && (
                  <div className="error-message">
                    Error: Invalid email or password
                  </div>
                )}
                <Box style={{ margin: "20px 0" }}>
                  <Button variant="text" onClick={onClickForgot}>
                    Forgot Password
                  </Button>
                </Box>
              </form>
            )}
            {/* end of login form */}

            {/* show forgot password form */}
            {isForgotPassword && !isSuccessSentEmail && (
              <div className="ForgotPassword">
                <div className="header">
                  <h2>Forgot your password?</h2>
                  <p>Enter your email address below to reset password.</p>
                </div>
                <TextField
                  onChange={(e) => setEmailForgot(e.target.value)}
                  fullWidth
                  label="Email"
                />
                <br />
                <br />
                <Button
                  variant="contained"
                  onClick={_onSubmitForgot}
                  color="primary"
                >
                  Submit
                </Button>
                <BackToLogin />
              </div>
            )}
            {/* end forgot password form */}

            {/* Successfully forgotten a password */}
            {isForgotPassword && isSuccessSentEmail && (
              <div className="ForgotPasswordMessage">
                <div className="message">
                  <h2>Please Check Your Email</h2>
                  <p>
                    If your email is valid, a reset password instruction has
                    been sent to you.
                  </p>
                </div>
                <BackToLogin />
              </div>
            )}
            {/* End of successfully forgotten a password */}

            {isLoading && (
              <div className="loading">
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="footer">
        Copyright {getYear(new Date())} MLS Logistic Service Pte Ltd. All rights
        reserved.
      </div>
    </div>
  );
};

export default LoginPage;
