import { get } from "lodash";
import React from "react";
import { Location } from "../../types";
import { convertIsoToLocale } from "../../utils/date";
import { LocationContext } from "../contexts";
import { FieldProps } from "./types";

interface Props extends Omit<FieldProps, "source"> {
  locationIdSource?: string;
  timeSource?: string;
}

/**
 * Show location and the timing
 * @param props
 * @returns
 */
const DeliveryLocationField: React.FC<Props> = (props) => {
  const { locations } = React.useContext(LocationContext);
  const { record, locationIdSource = "", timeSource } = props;
  const [location, setLocation] = React.useState<Location>();
  const [time, setTime] = React.useState<string>();

  /**
   * Format the location
   */
  React.useEffect(() => {
    const locationId = get(record, locationIdSource);
    const found = locations.find((item) => item.id === locationId);
    if (found) {
      setLocation(found);
    }
  }, [locationIdSource, locations, record]);

  /**
   * Format the time
   */
  React.useEffect(() => {
    if (timeSource) {
      const isoString = get(record, timeSource);
      setTime(convertIsoToLocale(isoString));
    }
  }, [timeSource, record]);

  if (!location) {
    return <>-</>;
  }

  return (
    <div>
      <span>{location.name}</span>
      {time && <span>, {time}</span>}
    </div>
  );
};

export default DeliveryLocationField;

DeliveryLocationField.defaultProps = {
  label: "Delivery Location",
  addLabel: true,
};
