/* eslint-disable import/no-anonymous-default-export */
import Icon from "@material-ui/icons/AccountBox";
import { FC } from "react";
import {
  Create,
  CreateProps,
  Datagrid,
  Edit,
  EditProps,
  Filter,
  ImageField,
  List,
  ListProps,
  SelectInput,
  Show,
  ShowProps,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";
import CrudBreadcrumb from "../components/CrudBreadcrumb";
import MyImageInput from "../components/ImageInput";
import BusinessUnitInput from '../components/inputs/BusinessUnitInput';
import StatusField from "../components/StatusField";
import ToolbarWithoutDelete from "../components/ToolbarWithoutDelete";
import { UserStatus } from "../utils/const";

const ResourceList: FC<ListProps> = (props) => (
  <List
    {...props}
    exporter={false}
    filters={<ListFilter />}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="full_name" />
      <TextField source="user.email" label="Email" />
      <TextField source="business_unit.name" label="Business Unit" />
      <StatusField source="user.status" label="Status" />
    </Datagrid>
  </List>
);

const ListFilter: FC<any> = (props) => (
  <Filter {...props}>
    <TextInput source="id" label="ID" />
    <TextInput source="search" label="Full Name" />
    <TextInput source="phone" label="Phone" />
    <SelectInput
      source="user.status"
      label="Status"
      choices={[
        { id: UserStatus.ACTIVE, name: "Active" },
        { id: UserStatus.INACTIVE, name: "Inactive" },
        { id: UserStatus.PENDING, name: "Pending" },
      ]}
    />
    <BusinessUnitInput source='business_unit_id' alwaysOn />
  </Filter>
);

const ResourceShow: FC<ShowProps> = (props) => {
  return (
    <>
      <CrudBreadcrumb
        resourceName={Common.resourceName}
        resourceId={props.id}
        mode="show"
      />
      <Show {...props}>
        <SimpleShowLayout>
          <StatusField source="user.status" label="Status" />
          <TextField source="id" />
          <TextField source="user.email" label="Email" />
          <TextField source="user.chat_user_id" label="Chat Ref ID" />
          <TextField source="full_name" />
          <TextField source="phone" />
          <TextField source="business_unit.name" label="Business Unit" />
          <ImageField source="photo_profile_url" />
        </SimpleShowLayout>
      </Show>
    </>
  );
};

const ResourceEdit: FC<EditProps> = (props) => {
  return (
    <>
      <CrudBreadcrumb
        resourceName={Common.resourceName}
        resourceId={props.id}
        mode="edit"
      />
      <Edit {...props}>
        <SimpleForm redirect="show" toolbar={<ToolbarWithoutDelete />}>
          <TextField source="id" />
          <TextField source="user.email" label="Email" />
          <SelectInput
            source="user.status"
            label="Status"
            choices={[
              { id: UserStatus.ACTIVE, name: "Active" },
              { id: UserStatus.INACTIVE, name: "Inactive" },
              { id: UserStatus.PENDING, name: "Pending" },
            ]}
          />
          <TextInput source="full_name" />
          <TextInput source="password" />
          <TextInput source="phone" inputProps={{ maxlength: 8 }} />
          <BusinessUnitInput source='business_unit_id' />
          <MyImageInput
            source="photo_profile_url"
            label="Profile Photo"
            accept="image/*"
          />
        </SimpleForm>
      </Edit>
    </>
  );
};

const ResourceCreate: FC<CreateProps> = (props) => {
  return (
    <>
      <CrudBreadcrumb
        resourceName={Common.resourceName}
        resourceId={""}
        mode="create"
      />
      <Create {...props}>
        <SimpleForm>
          <TextInput source="user.email" type="email" label="Email" />
          <TextInput source="full_name" />
          <TextInput source="phone" inputProps={{ maxlength: 8 }} />
          <BusinessUnitInput source='business_unit_id' />
        </SimpleForm>
      </Create>
    </>
  );
};

const Common = {
  resourceName: "consultants",
};

export default {
  name: Common.resourceName,
  icon: Icon,
  list: ResourceList,
  show: ResourceShow,
  create: ResourceCreate,
  edit: ResourceEdit,
};
