export const ProductType = {
  INSTRUMENT: "instrument",
  IMPLANT: "implant",
  OTHERS: "others",
};

export const UserStatus = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  PENDING: "pending",
};

export const AllocationStatus = {
  NEW: "new",
  READY: "ready",
};
