import { Box, ListItem, ListItemText } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React from "react";
import { Allocation, BookingItem } from "../../../types";
import useStyles from "./styles";

interface BookingListItemProps {
  item: BookingItem;
  isSelected: boolean;
  onClick: (item: BookingItem) => void;
  allocations?: Allocation[];
}
const BookingListItem: React.FC<BookingListItemProps> = ({
  item,
  isSelected,
  onClick,
  allocations = [],
}) => {
  const classes = useStyles();
  const theme = useTheme();

  /**
   * count how many product instances are allocated to the Booking Item
   */
  const allocatedCount = allocations.filter(
    (allocation) => allocation.product_id === item.product_id
  ).length;

  const getAllocatedColor = () => {
    if (allocatedCount === item.quantity) {
      return theme.palette.success.main;
    }
    if (allocatedCount > item.quantity) {
      return theme.palette.warning.main;
    }
    return theme.palette.error.main;
  };

  return (
    <ListItem
      key={item.id}
      button
      onClick={() => onClick(item)}
      selected={isSelected}
      className={classes.listItem}
    >
      <ListItemText
        id={`item.id`}
        primary={`${item.product.name}`}
        secondary={
          <React.Fragment>
            <Box>Type: {item.product.type}</Box>
            <Box>Quantity Requested: {item.quantity}</Box>
            <Box color={getAllocatedColor()}>Allocated: {allocatedCount}</Box>
          </React.Fragment>
        }
      />
    </ListItem>
  );
};

export default BookingListItem;
