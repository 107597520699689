/* eslint-disable import/no-anonymous-default-export */
import Icon from "@material-ui/icons/Mail";
import React, { FC } from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Edit,
  EditProps,
  FieldProps,
  Filter,
  Labeled,
  List,
  ListProps,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  ShowProps,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useRecordContext,
} from "react-admin";
import CrudBreadcrumb from "../components/CrudBreadcrumb";

const resourceName = "templates";

const ResourceList: FC<ListProps> = (props) => (
  <List
    {...props}
    exporter={false}
    filters={<ListFilter />}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <ReferenceField source="consultant_id" reference="consultants">
        <TextField source="full_name" />
      </ReferenceField>
      <TextField source="name" />
      <DateField source="updated_at" showTime={true} />
    </Datagrid>
  </List>
);

const ListFilter: FC<any> = (props) => (
  <Filter {...props}>
    <TextInput source="id" label="ID" />
    <TextInput source="name" label="Name" />
    <ReferenceInput
      label="Consultant"
      source="consultant_id"
      reference="consultants"
    >
      <AutocompleteInput optionText="full_name" />
    </ReferenceInput>
  </Filter>
);

const ResourceShow: FC<ShowProps> = (props) => {
  // const breadcrumbPaths = [
  //   { link: `/${resourceName}`, title: `${capitalize(resourceName)}` },
  //   { link: `/${resourceName}/${props.id}/show`, title: `${props.id}` },
  // ];

  /**
   * Show formatted JSON content
   *
   * @param props
   * @returns
   */
  const JsonField: React.FC<FieldProps> = (props) => {
    const record = useRecordContext(props);
    const obj = JSON.parse(record.content);
    const value = JSON.stringify(obj, undefined, 2);
    return <pre style={{ overflowY: "scroll", height: "20vh" }}>{value}</pre>;
  };

  return (
    <>
      <CrudBreadcrumb
        resourceName={Common.resourceName}
        resourceId={props.id}
        mode="show"
      />
      <Show {...props}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="name" />
          <ReferenceField source="consultant_id" reference="consultants">
            <TextField source="full_name" />
          </ReferenceField>
          <TextField source="description" />
          <Labeled source="content">
            <JsonField source="content" />
          </Labeled>
        </SimpleShowLayout>
      </Show>
    </>
  );
};

const ResourceEdit: FC<EditProps> = (props) => (
  <>
    <CrudBreadcrumb
      resourceName={Common.resourceName}
      resourceId={props.id}
      mode="show"
    />
    <Edit {...props}>
      <SimpleForm>
        <TextField source="id" />
        <TextInput source="name" />
        <ReferenceInput source="consultant_id" reference="consultants">
          <SelectInput optionText="full_name" />
        </ReferenceInput>
        <TextInput source="description" />
        <TextInput multiline source="content" />
      </SimpleForm>
    </Edit>
  </>
);
const Common = {
  resourceName: "templates",
};
export default {
  name: resourceName,
  icon: Icon,
  list: ResourceList,
  show: ResourceShow,
  edit: ResourceEdit,
};
