import Box from "@material-ui/core/Box";
import React from "react";

interface Props {
  url: string;
}

const Thumbnail: React.FC<Props> = ({ url }) => (
  <Box
    style={{
      border: "1px solid #ccc",
      borderRadius: "4px",
      padding: "5px",
      margin: "5px",
      width: 200,
    }}
  >
    <a href={url} target="_blank" rel="noreferrer">
      <img src={url} alt="image1" style={{ maxWidth: "100%" }} />
    </a>
  </Box>
);

export default Thumbnail;
