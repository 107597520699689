import React from "react";
import {
  AutocompleteInput,
  DateInput,
  Filter,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin";
import BusinessUnitInput from '../../components/inputs/BusinessUnitInput';
import { BookingStatus } from "./util";

const BookingFilter: React.FC<any> = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="Booking ID" source="id" />
      <SelectInput
        source="status"
        choices={[
          { id: BookingStatus.NEW, name: "New" },
          { id: BookingStatus.ACCEPTED, name: "Accepted" },
          { id: BookingStatus.DELIVERED, name: "Delivered" },
          { id: BookingStatus.ALLOCATED, name: "Allocated" },
          { id: BookingStatus.SURGERY_STARTED, name: "Surgery Started" },
          { id: BookingStatus.REPORT_SUBMITTED, name: "Report Submitted" },
          { id: BookingStatus.INVOICED, name: "Invoiced Only" },
          { id: BookingStatus.TOPPED_UP, name: "Topped Up Only" },
          {
            id: BookingStatus.INVOICED_TOPPED_UP,
            name: "Invoiced and Topped Up",
          },
        ]}
      />
      <DateInput source="date" />
      <ReferenceInput
        label="Consultant"
        source="consultant_id"
        reference="consultants"
      >
        <AutocompleteInput
          optionText="full_name"
          shouldRenderSuggestions={(val: string) => {
            return val.trim().length > 2;
          }}
        />
      </ReferenceInput>
      <ReferenceInput label="Venue" source="hospital_id" reference="hospitals">
        <AutocompleteInput
          optionText="name"
          shouldRenderSuggestions={(val: string) => {
            return val.trim().length > 2;
          }}
        />
      </ReferenceInput>
      <ReferenceInput
        label="Procedure"
        source="procedure_id"
        reference="procedures"
        sort={{ field: "name", order: "ASC" }}
      >
        <AutocompleteInput
          optionText="name"
          shouldRenderSuggestions={(val: string) => {
            return val.trim().length > 2;
          }}
        />
      </ReferenceInput>
      <ReferenceInput
        label="Surgeon"
        source="surgeon_id"
        reference="surgeons"
        sort={{ field: "name", order: "ASC" }}
      >
        <AutocompleteInput
          optionText="full_name"
          shouldRenderSuggestions={(val: string) => {
            return val.trim().length > 2;
          }}
        />
      </ReferenceInput>
      <BusinessUnitInput source='business_unit_id' />
    </Filter>
  );
};

export default BookingFilter;
