import React from "react";
import {
  ArrayField,
  Datagrid,
  DateField,
  TextField,
  useRecordContext,
} from "react-admin";
import { Booking, Revision } from "../../types";

interface Props {
  record?: Booking;
}
const BookingRevision: React.FC<Props> = (props) => {
  const { record: booking } = props;
  if (!booking) {
    return <></>;
  }

  const { new_revisions: revisions } = booking;
  if (revisions.length === 0) {
    return <></>;
  }

  console.log("Booking Revision props :>> ", props);
  return (
    <div>
      <hr />
      <h2>Revisions</h2>
      <ArrayField source="new_revisions" {...props}>
        <Datagrid>
          <TextField source="id" label="Revision ID" />
          <DateField source="created_at" showTime />

          <RevisionField />
        </Datagrid>
      </ArrayField>
    </div>
  );
};

export default BookingRevision;

interface RevisionSummaryFieldProps {
  record?: Revision;
  label?: string;
}
const RevisionField: React.FC<RevisionSummaryFieldProps> = (props) => {
  const record = useRecordContext(props);
  const changes = record.changes;

  let htmlSummary = "<h3>Attributes</h3>";
  htmlSummary += changes.attributes
    .map((attribute) => `- ${attribute.description}`)
    .join("<br/>");
  htmlSummary += "<br/><h3>Items</h3>";
  htmlSummary += changes.items
    .map((item) => `- ${item.description}`)
    .join("<br/>");

  return (
    <div>
      <span dangerouslySetInnerHTML={{ __html: htmlSummary }} />
    </div>
  );
};
RevisionField.defaultProps = {
  label: "Changes",
};
