import Icon from "@material-ui/icons/Settings";
import RichTextInput from "ra-input-rich-text";
import { FC } from "react";
import {
  AutocompleteInput,
  Datagrid,
  Edit,
  EditButton,
  EditProps,
  Filter,
  List,
  ListButton,
  ListProps,
  NumberInput,
  ReferenceInput,
  SaveButton,
  Show,
  ShowProps,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
} from "react-admin";
import CrudBreadcrumb from "../components/CrudBreadcrumb";

const ResourceList: FC<ListProps> = (props) => {
  return (
    <>
      <List
        {...props}
        exporter={false}
        filters={<ListFilter />}
        bulkActionButtons={false}
      >
        <Datagrid rowClick="show">
          <TextField source="key" />
          <TextField source="type" />
          <TextField source="description" />
        </Datagrid>
      </List>
    </>
  );
};

const ListFilter: FC<any> = (props) => (
  <Filter {...props}>
    {/* <TextInput source="id" label="ID" />
    <TextInput source="search" label="Key and Description" /> */}
    <ReferenceInput label="Key" source="key" reference="settings">
      <AutocompleteInput optionText="key" />
    </ReferenceInput>
    <ReferenceInput label="Type" source="type" reference="settings">
      <AutocompleteInput optionText="type" />
    </ReferenceInput>
    <ReferenceInput
      label="Description"
      source="description"
      reference="settings"
    >
      <AutocompleteInput optionText="description" />
    </ReferenceInput>
  </Filter>
);

const ResourceShow: FC<ShowProps> = (props) => {
  // const breadcrumbPaths = [
  //   { link: "/settings", title: "Settings" },
  //   { link: `/settings/${props.id}/show`, title: `${props.id}` },
  // ];

  return (
    <>
      <CrudBreadcrumb
        resourceName={Common.resourceName}
        resourceId={props.id}
        mode="show"
      />
      <Show {...props} actions={<ShowActions />}>
        <SimpleShowLayout>
          <TextField source="key" />
          <TextField source="type" />
          <TextField source="description" />
          <TextField source="value" />
        </SimpleShowLayout>
      </Show>
    </>
  );
};

const ShowActions: FC<any> = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const SettingEditToolbar: FC<any> = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);

const ResourceEdit: FC<EditProps> = (props) => {
  return (
    <>
      <CrudBreadcrumb
        resourceName={Common.resourceName}
        resourceId={props.id}
        mode="edit"
      />
      <Edit {...props}>
        <SimpleForm toolbar={<SettingEditToolbar />}>
          <TextField source="key" />
          <TextInput source="type" />
          <TextInput source="description" />
          <SettingInput source="value" />
        </SimpleForm>
      </Edit>
    </>
  );
};

const SettingInput = (props: any) => {
  const {
    record: { type },
  } = props;

  switch (type) {
    case "html":
      return <RichTextInput {...props} />;
    case "number":
      return <NumberInput {...props} />;
    default:
      return <TextInput multiline {...props} />;
  }
};
const Common = {
  resourceName: "settings",
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: Common.resourceName,
  icon: Icon,
  list: ResourceList,
  show: ResourceShow,
  edit: ResourceEdit,
};
