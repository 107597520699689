import { defaultTheme } from "react-admin";
import merge from "lodash/merge";

import indigo from "@material-ui/core/colors/indigo";
import pink from "@material-ui/core/colors/pink";
import red from "@material-ui/core/colors/red";

export const theme = merge({}, defaultTheme, {
  palette: {
    primary: indigo,
    secondary: pink,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    h3: {
      marginBottom: "0px !important",
    },
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  overrides: {
    RaFormInput: {
      input: {
        width: "100%",
      },
    },
    RaPaginationActions: {
      button: {
        color: "black",
        backgroundColor: "white",
        "&:hover": {
          backgroundColor: "#fff !important",
        },
      },
      currentPageButton: {
        color: "#000000",
        fontWeight: "bold",
        backgroundColor: "#f9f9f9",
      },
    },
    MuiFormControl: {
      marginDense: {
        width: "100%",
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "white !important",
        border: "1px solid #eaeaea",
        borderRadius: "4px",
      },
      underline: {
        "&:before": {
          border: "none !important",
        },
      },
    },
    RaAutocompleteArrayInput: {
      inputInput: {
        border: "none !important",
      },
    },
    MuiAppBar: {
      colorSecondary: {
        color: "#ffffff",
        backgroundColor: "#02225f",
      },
    },
    MuiPaper: {
      elevation4: {
        boxShadow: "0px 0px 18px rgba(0,0,0,19%)",
      },
    },
    MuiFormHelperText: {
      contained: {
        display: "none",
      },
    },
    // RaSidebar: {
    //   drawerPaper: {
    //     paddingTop: "20px",
    //     backgroundColor: "#fff",
    //   },
    // },
    RaLayout: {
      content: {
        "@media (min-width: 0px)": {
          paddingLeft: "24px",
        },
      },
    },
    // RaMenuItemLink: {
    //   root: {
    //     color: "#546e7a",
    //   },
    //   active: {
    //     color: "#fff",
    //     backgroundColor: "#02225f",
    //   },
    // },
    // MuiListItemIcon: {
    //   root: {
    //     color: "#546e7a",
    //   },
    // },
    // MuiListItem: {
    //   button: {
    //     "&:hover": {
    //       backgroundColor: "rgb(0 0 0 / 38%)",
    //     },
    //     "&:hover:active": {
    //       backgroundColor: "rgb(0 0 0 / 38%)",
    //     },
    //   },
    // },
    RaButton: {
      button: {
        marginLeft: "14px",
        backgroundColor: "#02225f",
        color: "white",
        "&:hover": {
          backgroundColor: "#1A237E !important",
        },
      },
    },
    RaDeleteWithUndoButton: {
      deleteButton: {
        marginLeft: "14px",
        backgroundColor: "#f44336",
        color: "white",
        "&:hover": {
          backgroundColor: "#B71C1C !important",
        },
      }
    },
    // MuiTypography: {
    //   colorTextSecondary: {
    //     color: "rgb(255 255 255 / 54%)",
    //   },
    // },
    MuiBreadcrumbs: {
      ol: {
        color: "black",
      },
    },
    RaLogout: {
      icon: {
        color: "rgba(0, 0, 0, 0.54)",
      },
    },
    MuiButton: {
      // override the styles of all instances of this component
      // root: {
      //   // Name of the rule
      //   backgroundColor: "#2196f3",
      //   color: "white",
      //   "&:hover": {
      //     backgroundColor: "#006cc1",
      //   },
      // },
      contained: {
        marginLeft: 10,
      },
      textSizeSmall: {
        padding: "5px 10px",
        fontSize: "0.8125rem",
      },
      text: {
        paddingLeft: 0,
      },
      containedSecondary: {
        marginLeft: 0,
      },
      containedPrimary: {
        backgroundColor: "#02225f",
        "&:hover": {
          backgroundColor: "#1A237E",
        },
      },
    },
  },
});
