import Icon from "@material-ui/icons/ReportProblem";
import { get } from "lodash";
import React from "react";
import { ProductInstance } from "../../types";
import { ProductInstanceChip } from "../Chip";
import IdField from "./IdField";
import { FieldProps } from "./types";

interface Props extends Omit<FieldProps, "source"> {
  source?: string;
}

const ProductInstanceField: React.FC<Props> = (props) => {
  const { record, source } = props;
  const originalResource: ProductInstance = source
    ? get(record, source)
    : record;

  if (!originalResource) return <></>;
  const resource = {
    ...originalResource,
    status: originalResource.name,
  };

  return (
    <div style={{ position: "relative", padding: 1, lineHeight: "initial" }}>
      <IdField record={resource}>
        {React.createElement(ProductInstanceChip)}
      </IdField>
      {resource.open_issues && resource.open_issues.length > 0 && (
        <Icon
          color="error"
          style={{ position: "absolute", left: 0, top: -10 }}
        />
      )}
    </div>
  );
};

ProductInstanceField.defaultProps = {
  label: "Set",
  addLabel: true,
};

export default ProductInstanceField;
