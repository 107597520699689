import { Box, Card, List, Typography } from "@material-ui/core";
import { filter, get } from "lodash";
import React from "react";
import {
  Loading,
  Record,
  useDataProvider,
  useNotify,
  useQuery,
  useRedirect,
} from "react-admin";
import {
  Allocation,
  Booking,
  BookingItem,
  ProductInstance,
} from "../../../types";
import { ProductInstanceStatus } from "../../productInstances/util";
import { BookingStatus, can, OrderActions } from "../util";
import AllocationFilter from "./AllocationFilter";
import AllocationItem from "./AllocationItem";
import BookingListItem from "./BookingListItem";
import ProductInstanceItem from "./ProductInstanceItem";
import useStyles from "./styles";

interface SelectInstancesProps {
  productId: number;
  allocations: Allocation[];
  onAllocate: (instance: ProductInstance) => void;
}
const SelectInstances = (props: SelectInstancesProps) => {
  const classes = useStyles();
  const { productId, allocations, onAllocate } = props;
  const { data: instances, loading } = useQuery({
    type: "getList",
    resource: "product-instances",
    payload: {
      filter: { product_id: productId, status: ProductInstanceStatus.ACTIVE },
    },
  });
  // const items: ProductInstance[] = instances;
  const [freeInstances, setFreeInstances] = React.useState<ProductInstance[]>(
    []
  );

  React.useEffect(() => {
    if (!instances) return;

    const res = instances.filter((instance: ProductInstance) =>
      allocations.findIndex(
        (allocation) => allocation.product_instance_id === instance.id
      ) === -1
        ? true
        : false
    );
    setFreeInstances(res);
  }, [allocations, instances]);

  if (loading || !instances) return <Box />;

  return (
    <List dense className={classes.list}>
      {freeInstances
        .sort((a: ProductInstance, b: ProductInstance) => a.id - b.id) // Sort by id
        .map((instance: ProductInstance) => (
          <ProductInstanceItem
            key={instance.id}
            instance={instance}
            allocations={allocations}
            onAllocate={onAllocate}
          />
        ))}
    </List>
  );
};

/**
 * Show Product Instances allocate to this booking
 */
interface ShowAllocatedInstancesProps {
  allocations: Allocation[];
  onRemoveAllocation?: (instance: ProductInstance) => void;
  selectedProductId?: number;
}
const ShowAllocatedInstances = (props: ShowAllocatedInstancesProps) => {
  const classes = useStyles();
  const { allocations, onRemoveAllocation, selectedProductId = 0 } = props;
  // const { allocations } = booking;

  const sortAllocations = () => {
    if (!selectedProductId) {
      return allocations.sort((a, b) => b.id - a.id);
    }

    const selected = allocations
      .filter((allocation) => allocation.product_id === selectedProductId)
      .sort((a, b) => b.id - a.id);

    // const notSelected = allocations
    //   .filter(
    //     (allocation) =>
    //       allocation.product_instance.product_id != selectedProductId
    //   )
    //   .sort((a, b) => b.id - a.id);

    // const combined = [...selected, ...notSelected];
    // console.log("selected :>> ", selected);
    return selected;
  };

  return (
    <List dense className={classes.list}>
      {allocations &&
        sortAllocations().map((allocation) => (
          <AllocationItem
            allocation={allocation}
            onRemoveAllocation={onRemoveAllocation}
            key={allocation.id}
          />
        ))}
    </List>
  );
};

/**
 * The container component
 *
 * @param props
 */
interface AllocateProps {
  bookingId: number;
}
const Allocate: React.FC<AllocateProps> = ({ bookingId }) => {
  const classes = useStyles();
  const notify = useNotify();
  const {
    data: booking,
    loading,
  }: { data?: Booking; loading?: any; error?: any } = useQuery({
    type: "getOne",
    resource: "bookings",
    payload: { id: bookingId },
  });
  const [items, setItems] = React.useState<BookingItem[]>([]);
  const [filteredItems, setFilteredItems] = React.useState<BookingItem[]>([]);
  const [selectedItem, setSelectedItem] = React.useState<BookingItem>();
  const [allocations, setAllocations] = React.useState<Allocation[]>([]);
  const [type, setType] = React.useState("");
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const [canAllocate, setCanAllocate] = React.useState(false);

  React.useEffect(
    () => console.log("allocations :>> ", allocations),
    [allocations]
  );

  // change the `canAllocate` value, based on booking status
  React.useEffect(() => {
    if (!booking) {
      return;
    }

    setCanAllocate(booking.status === BookingStatus.ACCEPTED);
  }, [booking]);

  // load Allocation[] when Booking is updated
  React.useEffect(() => {
    if (booking) {
      setAllocations(booking.allocations);
    }
  }, [booking]);

  // load Booking Items when booking is changed
  React.useEffect(() => {
    const allItems = get(booking, "items", []);
    const sorted = allItems.sort((a, b) => {
      if (a.product.type < b.product.type) {
        return -1;
      }
      return 1;
    });
    setItems(sorted);
  }, [booking]);

  // When items are updated, ensure that filtered items
  // are updated too.
  React.useEffect(() => {
    setFilteredItems(items);
  }, [items]);

  // when item filter is updated, ensure that filtered items
  // are updated.
  React.useEffect(() => {
    // if type is not empty, filter items from booking
    if (type !== "") {
      setFilteredItems(filter(items, (x) => x.product.type === type));
    }
    if (type === "") {
      setFilteredItems(items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  /**
   * Handle allocation of Product Instance to Booking.
   * If instance is already in allocation list, then no need to do any action.
   * Otherwise, add ProductInstance to the allocations.
   *
   * @param instance
   */
  const handleAllocate = (instance: ProductInstance) => {
    const newAllocation = {
      booking_id: booking?.id,
      product_instance_id: instance.id,
    } as Allocation;

    dataProvider
      .update("bookings", {
        id: booking ? booking.id : 0,
        data: {
          action: OrderActions.ALLOCATE,
          allocations: [...allocations, newAllocation],
        },
        previousData: booking as Record,
      })
      .then(({ data }) => setAllocations(data.allocations))
      .catch((err) => notify(err.message, "error"));
  };

  /**
   * Remove a ProductInstance from Allocations
   * @param instance
   */
  const handleRemoveAllocation = (instance: ProductInstance) => {
    const newAllocations = allocations.filter(
      (allocation) => allocation.product_instance_id !== instance.id
    );
    dataProvider
      .update("bookings", {
        id: booking ? booking.id : 0,
        data: {
          action: OrderActions.ALLOCATE,
          allocations: newAllocations,
        },
        previousData: booking as Record,
      })
      .then(({ data }) => {
        console.log("data.allocations", data, data.allocations);
        setAllocations(data.allocations);
      })
      .catch((err) => notify(err.message, "error"));
  };

  if (loading) return <Loading />;
  // if (error) return <Error />;
  if (!booking) {
    redirect(`/bookings`);
    return <Loading />;
  }

  return (
    <Box>
      <Box display="flex">
        <Box flex={1} mr="1em" style={{ height: "80vh", overflowY: "scroll" }}>
          <Card style={{ padding: "1rem" }}>
            <Typography variant="h6" gutterBottom>
              Items Requested
            </Typography>
            <List dense className={classes.list}>
              <AllocationFilter
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setType(e.target.value)
                }
              />
              {filteredItems.map((item) => (
                <BookingListItem
                  item={item}
                  isSelected={item === selectedItem}
                  onClick={setSelectedItem}
                  allocations={allocations}
                />
              ))}
            </List>
          </Card>
        </Box>

        {canAllocate && (
          <Box flex={2} mr="1em">
            <Card className={classes.card}>
              <Typography variant="h6" gutterBottom>
                Available Sets
              </Typography>
              {booking && can(booking, OrderActions.ALLOCATE) ? (
                <Box>
                  {selectedItem && (
                    <SelectInstances
                      productId={selectedItem.product_id}
                      allocations={allocations}
                      onAllocate={handleAllocate}
                    />
                  )}
                </Box>
              ) : (
                <Box>You cannot allocate Product Sets to this Booking.</Box>
              )}
            </Card>
          </Box>
        )}

        <Box flex={2} mr="1em">
          <Card className={classes.card}>
            <Typography variant="h6" gutterBottom>
              Allocated Sets
            </Typography>

            {/* A filter to show all allocations */}
            {selectedItem && (
              <Box
                onClick={() => setSelectedItem(undefined)}
                style={{
                  fontSize: "smaller",
                  cursor: "pointer",
                  textAlign: "right",
                  textDecoration: "underline",
                }}
              >
                Show All
              </Box>
            )}

            {canAllocate ? (
              <ShowAllocatedInstances
                allocations={allocations}
                onRemoveAllocation={handleRemoveAllocation}
                selectedProductId={selectedItem?.product_id}
              />
            ) : (
              // no need to pass onRemoveAllocation callback
              // because, at this booking status, we cannot change
              // allocations anymore.
              <ShowAllocatedInstances
                allocations={allocations}
                selectedProductId={selectedItem?.product_id}
              />
            )}
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default Allocate;
