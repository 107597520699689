import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { get } from "lodash";
import { useMutation, useNotify, useQueryWithStore } from "ra-core";
import React from "react";
import { Allocation, ProductInstance } from "../../../types";
import { AllocationActions, getName, useStyles } from "../util";
import ActionProps from "./AllocationActionProps";

const AssignButton: React.FC<ActionProps<Allocation>> = (props) => {
  const classes = useStyles();
  const notify = useNotify();
  const [open, setOpen] = React.useState(false);
  const { data: allocation } = props;
  const { data } = useQueryWithStore({
    type: "getList",
    resource: "product-instances",
    payload: {
      filter: { product_id: allocation.product_id },
      pagination: { page: 1, perPage: 1000 },
      sort: { field: "name", order: "asc" },
    },
  });
  const allProductInstances: ProductInstance[] | undefined = data;
  const [validProductInstances, setValidProductInstances] =
    React.useState<ProductInstance[]>();
  const [selectedInstanceId, setSelectedInstanceId] = React.useState<
    number | null
  >(get(allocation, "product_instance_id", null));
  const [isOkDisabled, setIsOkDisabled] = React.useState(true);

  // From allProductInstances, filter only the valid ones
  React.useEffect(() => {
    if (!allProductInstances) {
      return;
    }
    // cannot show existing product instance that is already assigned
    // to the allocation.
    // @todo need to filter based on availability
    // const res = allProductInstances.filter(
    //   (item) => item.id !== allocation.product_instance_id
    // );
    // setValidProductInstances(res);
    setValidProductInstances(allProductInstances);
  }, [allProductInstances]);

  // Disable the OK button if selected instance is the same
  // as existing instance.
  React.useEffect(
    () =>
      setIsOkDisabled(selectedInstanceId === allocation.product_instance_id),
    [allocation.product_instance_id, selectedInstanceId]
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /**
   * When user clicks the OK Button
   */
  const [handleOk] = useMutation(
    {
      type: "update",
      resource: "allocations",
      payload: {
        id: allocation.id,
        data: {
          product_instance_id: selectedInstanceId,
          action: AllocationActions.ASSIGN,
        },
      },
    },
    {
      onSuccess: ({ data }: { data: Allocation }) => {
        if (data.product_instance) {
          notify(
            `Box ${getName(
              data.product_instance
            )} has been assigned to this Allocation`,
            "success"
          );
        }
        setOpen(false);
      },
      onFailure: (e) => notify(get(e, "message"), "error"),
    }
  );

  /**
   * When user cancels the operation
   */
  const handleCancel = () => {
    setOpen(false);
    setSelectedInstanceId(allocation.product_instance_id ?? null);
  };

  return (
    <Box>
      <Button variant="contained" onClick={handleClickOpen}>
        Assign
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        disableEscapeKeyDown
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title">Assign a Box</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Assign a box to this Allocation. If the Allocation has already had a
            box assigned, the Delivery Order to transfer the existing Box will
            be cancelled.
          </DialogContentText>
          <FormControl className={classes.formControl}>
            <InputLabel>Select the Box to Assign</InputLabel>
            <Select
              id="instance-id"
              value={selectedInstanceId}
              onChange={(e) => setSelectedInstanceId(get(e, "target.value"))}
            >
              {validProductInstances?.map((instance) => (
                <MenuItem value={instance.id}>{getName(instance)}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleOk} color="primary" disabled={isOkDisabled}>
            Assign
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AssignButton;
