import Icon from "@material-ui/icons/ChatBubble";
import { FC } from "react";
import {
  Datagrid,
  Filter,
  List,
  ListProps,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";
import StatusField from "../../components/StatusField";
import ChatShow from "./ChatShow";

const ResourceList: FC<ListProps> = (props) => (
  <List
    {...props}
    sort={{ field: "id", order: "DESC" }}
    exporter={false}
    filters={<ListFilter />}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="show">
      <TextField source="id" label="ID" />
      <TextField source="provider" />
      <StatusField source="status" />
      <TextField source="booking_id" />
    </Datagrid>
  </List>
);

const ListFilter: FC<any> = (props) => (
  <Filter {...props}>
    <TextInput source="id" label="ID" />
    <TextInput source="provider" />
    <SelectInput
      source="status"
      choices={[
        { id: "active", name: "active" },
        { id: "inactive", name: "inactive" },
      ]}
    />
    <TextInput source="booking_id" />
  </Filter>
);

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: "chats",
  icon: Icon,
  list: ResourceList,
  show: ChatShow,
};
