/* eslint-disable import/no-anonymous-default-export */
import { Route } from "react-router-dom";
import About from "./pages/about";
import Report from "./pages/reports";

export default [
  <Route exact path="/reports" render={() => <Report />} />,
  <Route exact path="/about" render={() => <About />} />,
  // <Route exact path="/privacy-policy" render={() => <PrivacyPolicy />} />,
  // <Route exact path="/gst" render={() => <GST />} />,
  // <Route exact path="/reports" render={() => <Reports />} />,
  // <Route exact path="/init-year" render={() => <InitICS />} />,
];
