import { get } from "lodash";
import { Booking } from "../../types";
import { foundIn } from "../../utils";

export const BookingStatus = {
  NEW: "new",
  REVISE: "revise",
  ACCEPTED: "accepted",
  ALLOCATED: "allocated",
  DELIVERED: "delivered",
  SURGERY_STARTED: "surgery_started",
  REPORT_SUBMITTED: "report_submitted",
  INVOICED: "invoiced",
  TOPPED_UP: "topped_up",
  CANCELLED: "cancelled",
  INVOICED_TOPPED_UP: "invoiced_topped_up",
};

export const OrderActions = {
  REVISE: "revise",
  ACCEPT: "accept",
  CANCEL: "cancel",
  ALLOCATE: "allocate",
  FINISH_ALLOCATING: "finish_allocating",
  UNFINISH_ALLOCATING: "unfinish_allocating",
  FINISH_DELIVERING: "finish_delivering",
  START_SURGERY: "start_surgery",
  SUBMIT_REPORT: "submit_report",
  SEND_INVOICE: "send_invoice",
  TOP_UP: "top_up",
};

export const StatusColor = {
  NEW: "#025F11", //same with User Active
  ACCEPTED: "#4E05DF",
  ALLOCATED: "#2196F3",
  DELIVERED: "#5F3F02", //same with Issue Open
  DEFAULT: "#3f51b5",
  INACTIVE: "#aaa",
};

export const can = (booking: Booking, action: string): boolean => {
  switch (action) {
    case OrderActions.ACCEPT:
      return foundIn(booking.status, [BookingStatus.NEW]);

    case OrderActions.REVISE:
      return foundIn(booking.status, [
        BookingStatus.NEW,
        BookingStatus.ACCEPTED,
      ]);

    case OrderActions.ALLOCATE:
      return foundIn(booking.status, [BookingStatus.ACCEPTED]);

    case OrderActions.FINISH_ALLOCATING:
      return foundIn(booking.status, [BookingStatus.ACCEPTED]);

    case OrderActions.FINISH_DELIVERING:
      return foundIn(booking.status, [BookingStatus.ALLOCATED]);

    case OrderActions.START_SURGERY:
      return foundIn(booking.status, [BookingStatus.DELIVERED]);

    case OrderActions.SUBMIT_REPORT:
      return foundIn(booking.status, [
        BookingStatus.ALLOCATED,
        BookingStatus.DELIVERED,
        BookingStatus.SURGERY_STARTED,
        BookingStatus.ACCEPTED,
      ]);

    case OrderActions.SEND_INVOICE:
      return foundIn(booking.status, [
        BookingStatus.REPORT_SUBMITTED,
        BookingStatus.TOPPED_UP,
      ]);

    case OrderActions.TOP_UP:
      return foundIn(booking.status, [
        BookingStatus.REPORT_SUBMITTED,
        BookingStatus.INVOICED,
      ]);

    case OrderActions.CANCEL:
      return foundIn(booking.status, [
        BookingStatus.NEW,
        BookingStatus.ACCEPTED,
        BookingStatus.DELIVERED,
      ]);

    case OrderActions.UNFINISH_ALLOCATING:
      return foundIn(booking.status, [BookingStatus.ALLOCATED]);

    default:
      return false;
  }
};

export const IssueStatus = {
  OPEN: "open",
  RESOLVED: "resolved",
};

export const stringify = (booking: Booking) => {
  return `ID: ${booking.id}
Status: ${get(booking, "status")}
${get(booking, "consultant.full_name")}
${get(booking, "location.name")}
`;
};

export const stringifyHtml = (booking: Booking) => {
  return `<div>Hello</div>`;
};
