import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { get } from "lodash";
import React, { FC } from "react";
import {
  EditButton,
  ListButton,
  Loading,
  ShowProps,
  TopToolbar,
  useQueryWithStore,
} from "react-admin";
import CrudBreadcrumb from "../../components/CrudBreadcrumb";
import BookingSummary from "./BookingSummary";
import Conversation from "./Conversation";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ShowActions: FC<any> = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const ChatShow: FC<ShowProps> = (props) => {
  const { id } = props;
  const { loaded, data } = useQueryWithStore({
    type: "getOne",
    resource: "chats",
    payload: { id: id },
  });
  const [bookingId, setBookingId] = React.useState<number>();

  React.useEffect(() => console.log(data), [data]);
  React.useEffect(() => setBookingId(get(data, "booking_id")), [data]);

  if (!loaded) return <Loading />;
  if (!bookingId) return <Loading />;

  console.log(props);
  return (
    <Box>
      <CrudBreadcrumb resourceName={"chats"} resourceId={id} mode="show" />
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <BookingSummary id={bookingId} />
        </Grid>
        <Grid item xs={8}>
          {props.id && <Conversation id={props.id} />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChatShow;
