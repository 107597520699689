import Icon from "@material-ui/icons/MeetingRoom";
import { FC } from "react";
import {
  AutocompleteInput,
  Create,
  CreateProps,
  Datagrid,
  Edit,
  EditProps,
  Filter,
  List,
  ListProps,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin";
import CrudBreadcrumb from "../../components/CrudBreadcrumb";
import ResourceShow from "./show";

const ResourceList: FC<ListProps> = (props) => (
  <List
    {...props}
    exporter={false}
    filters={<ListFilter />}
    bulkActionButtons={false}
    title="Department"
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" label="Name" />
      <ReferenceField
        label="Hospital"
        source="hospital_id"
        reference="hospitals"
      >
        <TextField source="name" />
      </ReferenceField>
      {/* <ReferenceField  label="Location" source="id" reference="locations">
          <TextField source="name" />
      </ReferenceField> */}
    </Datagrid>
  </List>
);

const ListFilter: FC<any> = (props) => (
  <Filter {...props}>
    <TextInput source="id" label="ID" />
    <TextInput source="search" label="Name" />
    <ReferenceInput label="Hospital" source="hospital_id" reference="hospitals">
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    {/* <ReferenceInput label="Location" source="id" reference="locations">
      <AutocompleteInput optionText="name" />
    </ReferenceInput> */}
  </Filter>
);

const ResourceEdit: FC<EditProps> = (props) => {
  return (
    <>
      <CrudBreadcrumb
        resourceName={Common.resourceName}
        resourceId={props.id}
        mode="edit"
      />
      <Edit {...props} title={`Department #${props.id} Edit`}>
        <SimpleForm redirect="show">
          <TextField source="id" />
          <TextInput source="name" label="Name" />
          <ReferenceInput
            label="Hospital"
            source="hospital_id"
            reference="hospitals"
            disabled
          >
            <SelectInput source="name" />
          </ReferenceInput>
          {/* <ReferenceInput  label="Location" source="id" reference="locations">
            <SelectInput source="name" />
          </ReferenceInput> */}
        </SimpleForm>
      </Edit>
    </>
  );
};

const ResourceCreate: FC<CreateProps> = (props) => {
  return (
    <>
      <CrudBreadcrumb
        resourceName={Common.resourceName}
        resourceId={""}
        mode="create"
      />
      <Create {...props} title="Create Department">
        <SimpleForm>
          <TextInput source="name" label="Name" />
          <ReferenceInput
            label="Hospital"
            source="hospital_id"
            reference="hospitals"
          >
            <SelectInput source="name" />
          </ReferenceInput>
          {/* <ReferenceInput  label="Location" source="id" reference="locations">
            <SelectInput source="name" />
          </ReferenceInput> */}
        </SimpleForm>
      </Create>
    </>
  );
};

const Common = {
  resourceName: "hospital-rooms",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: Common.resourceName,
  icon: Icon,
  list: ResourceList,
  show: ResourceShow,
  create: ResourceCreate,
  edit: ResourceEdit,
};
