import { Color } from "../types";

export const foundIn = (needle: string, haystack: string[]) =>
  haystack.find((el) => el === needle) ? true : false;

/**
 * A helper file to find text and return the style
 *
 * @param text
 * @param colors
 * @returns
 */
export const getStyle = (text: string, colors: Color[]) => {
  const defaultValue = {
    backgroundColor: "black",
    color: "white",
  };
  const found = colors.find((color) => color.key === text);
  if (found) {
    return {
      backgroundColor: found.background_hex,
      color: found.text_hex,
    };
  }

  return defaultValue;
};
