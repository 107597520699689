import Icon from "@material-ui/icons/Traffic";
import { capitalize } from "lodash";
import { FC } from "react";
import {
  Create,
  CreateProps,
  Datagrid,
  Edit,
  EditProps,
  Filter,
  List,
  ListProps,
  SaveButton,
  Show,
  ShowProps,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  Toolbar,
} from "react-admin";
import CrudBreadcrumb from "../components/CrudBreadcrumb";
import TitleBreadcrumb from "../components/TitleBreadcrumb";

const resourceName = "colours";

const ResourceList: FC<ListProps> = (props) => (
  <List
    {...props}
    exporter={false}
    filters={<ListFilter />}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="show">
      <TextField source="key" />
      <TextField source="background_hex" />
      <TextField source="text_hex" />
    </Datagrid>
  </List>
);

const ListFilter: FC<any> = (props) => (
  <Filter {...props}>
    <TextInput source="key" label="Key" />
    <TextInput source="background_hex" label="Background_hex" />
    <TextInput source="text_hex" label="Text_hex" />
  </Filter>
);

const ResourceShow: FC<ShowProps> = (props) => {
  const breadcrumbPaths = [
    { link: `/${resourceName}`, title: `${capitalize(resourceName)}` },
    { link: `/${resourceName}/${props.id}/show`, title: `${props.id}` },
  ];

  return (
    <>
      <TitleBreadcrumb paths={breadcrumbPaths} />
      <Show {...props}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="key" />
          <TextField source="background_hex" />
          <TextField source="text_hex" />
        </SimpleShowLayout>
      </Show>
    </>
  );
};

const IssueEditToolbar: FC<any> = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);

const ResourceEdit: FC<EditProps> = (props) => (
  <Edit {...props}>
    <SimpleForm toolbar={<IssueEditToolbar />}>
      <TextField source="id" />
      <TextField source="key" />
      <TextInput source="background_hex" />
      <TextInput source="text_hex" />
    </SimpleForm>
  </Edit>
);

const ResourceCreate: FC<CreateProps> = (props) => {
  return (
    <>
      <CrudBreadcrumb
        resourceName={Common.resourceName}
        resourceId={""}
        mode="create"
      />
      <Create {...props}>
        <SimpleForm>
          <TextField source="id" />
          <TextInput source="key" />
          <TextInput source="background_hex" />
          <TextInput source="text_hex" />
        </SimpleForm>
      </Create>
    </>
  );
};

const Common = {
  resourceName: "colours",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: "colors",
  icon: Icon,
  list: ResourceList,
  show: ResourceShow,
  edit: ResourceEdit,
  create: ResourceCreate,
};

export const IssueStatus = {
  OPEN: "open",
  RESOLVED: "resolved",
};
