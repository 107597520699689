/* eslint-disable react-hooks/exhaustive-deps */
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { addHours, formatISO } from "date-fns";
import { get, isArray, isObject, values } from "lodash";
import moment from "moment";
import { useDataProvider } from "ra-core";
import React from "react";
import { Calendar, Event, momentLocalizer } from "react-big-calendar";
import { translateStatus } from "../../components/StatusField";
import { BookingStatus, stringify } from "../../pages/bookings/util";
import BookingSummary from "../../pages/chats/BookingSummary";
import { Booking } from "../../types";
import { getStyle } from "../../utils";
import { StatusColorContext } from "../contexts";
import "./styles.css";

interface Props {}
const BookingCalendar: React.FC<Props> = (props) => {
  const localizer = momentLocalizer(moment);
  const dataProvider = useDataProvider();
  const [dateFrom, setDateFrom] = React.useState<string>();
  const [dateTo, setDateTo] = React.useState<string>();
  const [bookings, setBookings] = React.useState<Booking[]>([]);
  const [events, setEvents] = React.useState<Event[]>([]);
  const [selectedEvent, setSelectedEvent] = React.useState<Event>();
  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);
  const { colors } = React.useContext(StatusColorContext);

  const onRangeChange = (range: Date[] | object) => {
    console.log("range :>> ", range);
    if (isArray(range)) {
      range.sort((a, b) => (a < b ? 1 : 0));
      setDateFrom(formatISO(range[0]));
      setDateTo(formatISO(range[range.length - 1]));
    } else if (isObject(range)) {
      setDateFrom(formatISO(get(range, "start")));
      setDateTo(formatISO(get(range, "end")));
    }
  };

  // initialise the daterange to start and end of this week
  React.useEffect(() => {
    const start = moment().startOf("week").toDate();
    const end = moment().endOf("week").toDate();
    setDateFrom(formatISO(start));
    setDateTo(formatISO(end));
  }, []);

  React.useEffect(() => {
    console.log("dateFrom :>> ", dateFrom);
    console.log("dateTo :>> ", dateTo);
    if (dateFrom && dateTo) {
      fetchData();
    }
  }, [dateFrom, dateTo]);

  /**
   * Map bookings to events
   */
  React.useEffect(
    () =>
      setEvents(
        bookings.map((booking) => {
          const start = new Date(booking.date);
          const end = addHours(start, 3);
          return {
            title: stringify(booking),
            status: booking.status,
            date: booking.date,
            start,
            end,
            resource: booking,
          };
        })
      ),
    [bookings]
  );

  const fetchData = () =>
    dataProvider
      .getList("bookings", {
        pagination: {
          page: 1,
          perPage: 1000,
        },
        sort: {
          field: "date",
          order: "asc",
        },
        filter: {
          date_from: dateFrom,
          date_to: dateTo,
        },
      })
      .then(({ data }) => {
        setBookings(data as Booking[]);
        console.debug("data :>> ", data);
      })
      .catch((error) => {
        console.log("error :>> ", error);
      });

  /**
   * Show dialog when event in the calendar is selected
   * @param event
   */
  const handleSelectEvent = (event: Event) => {
    setSelectedEvent(event);
    setIsDialogOpen(true);
  };
  const handleClose = () => {
    setIsDialogOpen(false);
  };

  React.useEffect(
    () => console.log("selectedEvent :>> ", selectedEvent),
    [selectedEvent]
  );

  /**
   * Go to booking detail
   * @returns
   */
  const handleGoToBooking = () => {
    if (!selectedEvent) {
      return;
    }
    const win = window.open(
      `/#/bookings/${selectedEvent.resource.id}/show`,
      "_blank"
    );
    win?.focus();
  };

  return (
    <Box height="600px">
      <Calendar
        events={events}
        startAccessor="start"
        endAccessor="end"
        localizer={localizer}
        defaultView="week"
        views={["month", "week", "day"]}
        onRangeChange={onRangeChange}
        eventPropGetter={(event: any, start, end, isSelected) => {
          const newStyle = getStyle(event.status, colors);

          return {
            className: "",
            style: newStyle,
          };
        }}
        // onSelectEvent={event => alert(event.title)}
        onSelectEvent={(event: Event) => handleSelectEvent(event)}
      />

      {selectedEvent && (
        <Dialog
          open={isDialogOpen}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogTitle>{selectedEvent.title}</DialogTitle>
          <DialogContent>
            <BookingSummary id={selectedEvent.resource?.id} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDialogOpen(false)} color="secondary">
              Close
            </Button>
            <Button onClick={handleGoToBooking} color="primary">
              Go To Booking
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Box
        style={{
          border: "1px solid #ddd",
          borderRadius: "3px",
          margin: "10px",
          padding: "10px",
        }}
      >
        <h2>Legend</h2>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          {values(BookingStatus).map((status) => {
            return (
              <Chip
                label={translateStatus(status)}
                style={{
                  ...getStyle(status, colors),
                  minWidth: "100px",
                  height: "30px",
                  margin: "2px",
                }}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default BookingCalendar;
