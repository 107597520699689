import React from "react";
import { Layout, useQuery } from "react-admin";
import { Color, Location } from "../../types";
import { LocationContext, StatusColorContext } from "../contexts";
import Menu from "./Menu";

const MyLayout = (props: any) => {
  const [colors, setColors] = React.useState<Color[]>([]);
  const [locations, setLocations] = React.useState<Location[]>([]);

  // Get the list of colours and store it in the context.
  // So, next request by other component to get color, we
  // dont need to hit the API server.
  const { data: serverColors } = useQuery({
    type: "getList",
    resource: "colors",
    payload: {
      pagination: { page: 1, perPage: 1000 },
    },
  });
  React.useEffect(() => {
    if (!serverColors) return;
    setColors(serverColors);
  }, [serverColors]);

  // get list of locations and store it to context
  const { data: allLocations } = useQuery({
    type: "getList",
    resource: "locations",
    payload: {
      pagination: { page: 1, perPage: 1000 },
    },
  });
  React.useEffect(() => {
    if (!allLocations) return;
    setLocations(allLocations);
  }, [allLocations]);

  return (
    <StatusColorContext.Provider value={{ colors, setColors }}>
      <LocationContext.Provider value={{ locations, setLocations }}>
        <Layout {...props} menu={Menu} />
      </LocationContext.Provider>
    </StatusColorContext.Provider>
  );
};

export default MyLayout;
