import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useMutation, useNotify } from "ra-core";
import React from "react";
import { Booking } from "../../types";
import { OrderActions } from "./util";

interface Props {
  data: Booking;
}

const AcceptDialog: React.FC<Props> = (props) => {
  const { data } = props;
  const [open, setOpen] = React.useState(false);
  const notify = useNotify();
  const [handleAccept, { loading }] = useMutation(
    {
      type: "update",
      resource: "bookings",
      payload: { id: data.id ?? "", data: { action: OrderActions.ACCEPT } },
    },
    {
      onSuccess: ({ data }) => {
        notify(`Successfully accept a booking.`, "info");
      },
      onFailure: (error) =>
        notify(`Booking acceptance error: ${error.message}`, "warning"),
    }
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        color="primary"
        onClick={handleClickOpen}
        size={"small"}
        variant="contained"
      >
        Accept Booking
      </Button>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Accept Booking?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            After accepting the Booking, Admin will be able to assign Product
            Sets to Booking and Consultant will not be able to edit the Booking
            anymore.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Back
          </Button>
          <Button
            onClick={handleAccept}
            color="primary"
            disabled={loading}
            autoFocus
          >
            {loading ? "Please wait..." : "Accept"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AcceptDialog;
