import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Allocation, ProductInstance } from "../../types";
import { foundIn } from "../../utils";

export const AllocationActions = {
  ASSIGN: "assign",
  CREATE: "create",
  ADD_ISSUE: "add_issue",
  RESOLVE_ISSUE: "resolve_issue",
  UNASSIGN: "unassign",
  FINISH: "finish",
  CANCEL: "cancel",
};

export const AllocationStatusColor = {
  UNASSIGNED: "#827717", //same with User Active
  GOT_ISSUE: "#FFA000",
  ASSIGNED: "#FF6F00",
  DONE: "#C7C7C7", //same with Issue Open
  CANCELLED: "#E91E63",
};

export const canAllocation = (
  allocation: Allocation,
  action: string
): boolean => {
  switch (action) {
    // case AllocationActions.DELETE:
    //   // can only delete the Allocation, if the Booking status is
    //   // accepted or draft.
    //   return foundIn(get(allocation, "booking.status", ""), []);

    case  AllocationActions.ASSIGN:
      return foundIn(allocation.status, [AllocationStatus.ASSIGNED]);

    case  AllocationActions.UNASSIGN:
      return foundIn(allocation.status, [AllocationStatus.UNASSIGNED]);
    
    case  AllocationActions.ADD_ISSUE:
      return foundIn(allocation.status, [AllocationStatus.GOT_ISSUE]);
    
    // case  AllocationActions.UNASSIGN:
    //   return foundIn(allocation.status, [AllocationStatus.UNASSIGNED]);

    case  AllocationActions.FINISH:
        return foundIn(allocation.status, [AllocationStatus.DONE]);

    case  AllocationActions.CANCEL:
        return foundIn(allocation.status, [AllocationStatus.CANCELLED]);

    default:
      return false;
  }
};

export const AllocationStatus = {
  UNASSIGNED: "unassigned",
  GOT_ISSUE: "got_issue",
  ASSIGNED: "assigned",
  DONE: "done",
  CANCELLED: "cancelled",
};

export const getName = (instance: ProductInstance) => {
  return `${instance.name} (ID ${instance.id}, Product ID: ${instance.product_id})`;
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
    },
  })
);
