import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useMutation, useNotify } from "ra-core";
import React from "react";
import { Booking } from "../../types";
import { OrderActions } from "./util";

interface Props {
  data: Booking;
}

const FinishAllocating: React.FC<Props> = (props) => {
  const { data } = props;
  const [open, setOpen] = React.useState(false);
  const notify = useNotify();
  const [handleAccept] = useMutation(
    {
      type: "update",
      resource: "bookings",
      payload: {
        id: data.id ?? "",
        data: { action: OrderActions.FINISH_ALLOCATING },
      },
    },
    {
      onSuccess: ({ data }) => {
        notify(`Successfully mark the Booking.`, "info");
      },
      onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
    }
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        color="primary"
        onClick={handleClickOpen}
        size={"small"}
        variant="contained"
      >
        Lock Allocations
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Please Confirm"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            After "Locking" the Allocation, you will not be able to
            allocate/unallocate Product Sets to this Booking until you unlock
            it. Confirm?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAccept} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FinishAllocating;
