import React from 'react';
import { SaveButton, Toolbar } from 'react-admin';

const ToolbarWithoutDelete = (props: any) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);

export default ToolbarWithoutDelete;
