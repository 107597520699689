import Icon from "@material-ui/icons/Person";
import { FC } from "react";
import {
  Create,
  CreateProps,
  Datagrid,
  Edit,
  EditProps,
  Filter,
  List,
  ListProps,
  Show,
  ShowProps,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin";
import CrudBreadcrumb from "../components/CrudBreadcrumb";

const ResourceList: FC<ListProps> = (props) => (
  <List
    {...props}
    exporter={false}
    filters={<ListFilter />}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="full_name" />
    </Datagrid>
  </List>
);

const ListFilter: FC<any> = (props) => (
  <Filter {...props}>
    <TextInput source="id" label="ID" />
    <TextInput source="search" label="Full Name" />
  </Filter>
);

const ResourceShow: FC<ShowProps> = (props) => {
  return (
    <>
      <CrudBreadcrumb
        resourceName={Common.resourceName}
        resourceId={props.id}
        mode="show"
      />
      <Show {...props}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="full_name" />
        </SimpleShowLayout>
      </Show>
    </>
  );
};

const ResourceEdit: FC<EditProps> = (props) => {
  return (
    <>
      <CrudBreadcrumb
        resourceName={Common.resourceName}
        resourceId={props.id}
        mode="edit"
      />
      <Edit {...props}>
        <SimpleForm redirect="show">
          <TextField source="id" />
          <TextInput source="full_name" />
        </SimpleForm>
      </Edit>
    </>
  );
};

const ResourceCreate: FC<CreateProps> = (props) => {
  return (
    <>
      <CrudBreadcrumb
        resourceName={Common.resourceName}
        resourceId={""}
        mode="create"
      />
      <Create {...props}>
        <SimpleForm>
          <TextInput source="full_name" />
        </SimpleForm>
      </Create>
    </>
  );
};

const Common = {
  resourceName: "surgeons",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: Common.resourceName,
  icon: Icon,
  list: ResourceList,
  show: ResourceShow,
  create: ResourceCreate,
  edit: ResourceEdit,
};
