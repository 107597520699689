/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Client, User } from "@twilio/conversations";
import { Conversation } from "@twilio/conversations/lib/conversation";
import { Message } from "@twilio/conversations/lib/message";
import { get } from "lodash";
import React from "react";

interface Props {
  getToken: () => Promise<string>;
  conversationSid: string;
}
const useTwilio = ({ getToken, conversationSid }: Props) => {
  const [token, setToken] = React.useState("");
  const [client, setClient] = React.useState<Client>();
  const [statusString, setStatusString] = React.useState("connecting");
  const [status, setStatus] = React.useState("loading");
  const [conversationsReady, setConversationsReady] = React.useState(false);
  const [
    selectedConversation,
    setSelectedConversation,
  ] = React.useState<Conversation>();

  // to handle passing of state to event handler
  // https://medium.com/geographit/accessing-react-state-in-event-listeners-with-usestate-and-useref-hooks-8cceee73c559
  const [messages, _setMessages] = React.useState<Message[]>([]);
  const messagesRef = React.useRef(messages);
  const setMessages = (newMessages: Message[]) => {
    messagesRef.current = newMessages;
    _setMessages(newMessages);
  };
  const [userList, setUserList] = React.useState<User[]>([]);

  /**
   * get chat token from the server
   */
  const updateToken = () => {
    getToken()
      .then((newToken) => setToken(newToken))
      .catch((e) => {
        console.log(e);
        console.log("cannot get token");
      });
  };
  React.useEffect(updateToken, []);

  /**
   * initialise client when token is updated
   */
  const initialiseClient = () => {
    if (!token) {
      return;
    }
    // create client if it has not been updated
    if (!client) {
      Client.create(token)
        .then((val) => setClient(val))
        .catch((err) => console.log("err :>> ", err));
    } else {
      // otherwise, refresh the client's token
      client.updateToken(token);
    }
  };
  React.useEffect(initialiseClient, [token]);

  /**
   * Attach events to the client
   */
  React.useEffect(() => {
    if (!client) return;

    client.on("connectionStateChanged", (state) => {
      if (state === "connecting") {
        setStatusString("Connecting...");
        setStatus("loading");
      }
      if (state === "connected") {
        setStatusString("Type here...");
        setStatus("success");
      }
      if (state === "disconnecting") {
        setStatusString("Disconnecting...");
        setConversationsReady(false);
        setStatus("loading");
      }
      if (state === "disconnected") {
        setStatusString("Disconnected.");
        setConversationsReady(false);
        setStatus("loading");
      }
      if (state === "denied") {
        setStatusString("Failed to connect.");
        setConversationsReady(false);
        setStatus("error");
      }
    });

    client.on("conversationJoined", () => {
      // setConversations([...conversations, conversation]);
    });

    client.on("conversationLeft", () => {
      // setConversations([
      //   ...conversations.filter((it) => it !== thisConversation),
      // ]);
    });

    client.on("tokenAboutToExpire", () => updateToken());
    client.on("tokenExpired", () => updateToken());

    return () => {
      client.removeAllListeners();
    };
  }, [client]);

  /**
   * Fetching a conversation
   * @returns promise
   */
  const fetchConversation = () => {
    if (!client) {
      return;
    }

    return client
      .getConversationBySid(conversationSid)
      .then((conversation) => {
        setSelectedConversation(conversation);
        return conversation.getParticipants();
      })
      .then(async (participants) => {
        console.log("participants :>> ", participants);
        const userPromises = participants.map((participant) =>
          participant.getUser()
        );
        const users = await Promise.all(userPromises);
        setUserList(users);
      })
      .catch(() => setStatus("error"));
  };
  React.useEffect(() => {
    fetchConversation();
  }, [client, conversationSid]);

  /**
   * Initialise selectedConversation with handlers, i.e. handler when
   * new message is added.
   * Load messages when selectedConversation is changed.
   */
  React.useEffect(() => {
    if (!selectedConversation) {
      return;
    }

    selectedConversation
      .getMessages()
      .then((res: { items: Message[] }) => setMessages(res.items));

    selectedConversation.on("messageAdded", (newMessage: Message) => {
      setMessages([...messagesRef.current, newMessage]);
    });

    return () => {
      selectedConversation.removeAllListeners();
    };
  }, [selectedConversation]);

  /**
   * A helper function to get user's friendly name, given a user identity
   * @param identity userIdentity
   * @returns
   */
  const getUserName = (identity: string): string => {
    const selectedUser = userList.find((user) => user.identity === identity);
    if (!selectedUser) {
      return identity;
    }

    // we need to decide to return which name because a user can have multiple profile
    // The highest priority is to return admin name, followed by consultant, and driver.
    const attributes = selectedUser.attributes;
    const adminName = get(attributes, "admin.full_name", "");
    const consultantName = get(attributes, "consultant.full_name");
    const driverName = get(attributes, "driver.full_name", "");

    if (adminName !== "") return adminName;
    if (consultantName !== "") return consultantName;
    if (driverName !== "") return driverName;

    // if there's no profile at all, return the identity
    return identity;
  };

  return {
    client,
    status,
    userList,
    messages,
    getUserName,
    selectedConversation,
  };
};
export default useTwilio;
