import React from "react";
import { AllocationChip } from "../Chip";
import IdField from "./IdField";
import { FieldProps } from "./types";

interface Props extends Omit<FieldProps, "source"> {
  source?: string;
}
const Field: React.FC<Props> = (props) => {
  return <IdField {...props}>{React.createElement(AllocationChip)}</IdField>;
};

Field.defaultProps = {
  label: "Allocation",
  addLabel: true,
};

export default Field;
