import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import React, { FC } from "react";
import {
  Datagrid,
  DateField,
  List,
  ListProps,
  ReferenceField,
  TextField,
} from "react-admin";
import BookingCalendar from "../../components/BookingCalendar";
import { BookingIdField, HospitalRoomField } from "../../components/fields";
import BookingFilter from "./BookingFilter";

export const BookingList: FC<ListProps> = (props) => {
  const [isCalendarView, setIsCalendarView] = React.useState(false);

  return (
    <>
      <Box width="100%" textAlign="left">
        <Button
          variant="text"
          size="small"
          onClick={() => setIsCalendarView(!isCalendarView)}
        >{`Switch to ${isCalendarView ? "List" : "Calendar"} View`}</Button>
      </Box>

      {isCalendarView ? (
        <BookingCalendar />
      ) : (
        <List
          {...props}
          sort={{ field: "id", order: "DESC" }}
          exporter={false}
          bulkActionButtons={false}
          filters={<BookingFilter />}
        >
          <Datagrid>
            <BookingIdField />
            <DateField source="date" showTime={true} />
            <ReferenceField source="consultant_id" reference="consultants">
              <TextField source="full_name" />
            </ReferenceField>
            <HospitalRoomField
              source="hospital_room.name"
              hospitalSource="hospital.name"
            />
            <ReferenceField source="procedure_id" reference="procedures">
              <TextField source="name" />
            </ReferenceField>
            <TextField source="surgeon.full_name" label="Surgeon" />
            <TextField source="business_unit.name" label="Business Unit" />
            <DateField source="created_at" showTime />
          </Datagrid>
        </List>
      )}
    </>
  );
};
