import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    border: "1px solid #aaa",
    margin: "0.5rem 0",
    borderRadius: "3px",
    padding: "1rem",
    position: "relative",
  },
  card: {
    padding: "1rem",
  },
  buttonDanger: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  chips: {
    display: "flex",
    marginTop: theme.spacing(1),
    "& > div": {
      margin: theme.spacing(0.2),
    },
  },
}));

export default useStyles;
