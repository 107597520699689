import React from "react";
import { SelectInput } from "react-admin";

interface Props {
  source: string;
}
const options = [
  { id: "instrument", name: "instrument" },
  { id: "implant", name: "implant" },
  { id: "others", name: "others" },
];
const ProductTypeInput: React.FC<Props> = (props) => {
  return <SelectInput {...props} choices={options} />;
};

export default ProductTypeInput;
