import React from "react";
import { BookingChip } from "../Chip";
import IdField from "./IdField";
import { FieldProps } from "./types";

interface Props extends Omit<FieldProps, "source"> {
  source?: string;
}
const BookingField: React.FC<Props> = (props) => {
  return <IdField {...props}>{React.createElement(BookingChip)}</IdField>;
};

BookingField.defaultProps = {
  label: "Booking",
  addLabel: true,
};

export default BookingField;
