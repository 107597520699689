import Box from "@material-ui/core/Box";
import { get } from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { ProductInstance } from "../types";
import { getStyle } from "../utils";
import { StatusColorContext } from "./contexts";
import { translateStatus } from "./StatusField";

interface Props {
  resource: { id: number; status: string };
}

interface MyChipProps {
  resource: { id: number; status: string };
  resourceName: string;
  avatarCharacter?: string;
  style?: object;
}
const MyChip: React.FC<MyChipProps> = ({
  resource,
  resourceName,
  avatarCharacter,
  style,
}) => {
  const status = translateStatus(resource.status);
  const label = `${resource.id} ${status}`;
  const initial = avatarCharacter ?? resourceName.substring(0, 2).toUpperCase();
  const { colors } = React.useContext(StatusColorContext);

  return (
    <Box display="inline-block" style={{ padding: 1 }}>
      <Link
        to={`/${resourceName}/${resource.id}/show`}
        style={{
          ...getStyle(resource.status, colors),
          textDecoration: "none",
          display: "flex",
          padding: "2px",
          borderRadius: "16px",
          fontSize: "0.7rem",
          alignItems: "center",
          justifyContent: "center",
          ...style,
        }}
      >
        <Box
          style={{
            backgroundColor: "white",
            color: "#333",
            fontSize: "0.6rem",
            fontWeight: "bold",
            borderRadius: "50%",
            padding: "5px",
          }}
        >
          {initial}
        </Box>
        <Box
          style={{
            padding: "3px 5px ",
            fontWeight: "bold",
            whiteSpace: "nowrap",
          }}
        >
          {label}
        </Box>
      </Link>
    </Box>
  );
};

export const BookingChip: React.FC<Props> = ({ resource, ...rest }) => (
  <MyChip
    resource={resource}
    resourceName="bookings"
    avatarCharacter="BK"
    {...rest}
  />
);

export const AllocationChip: React.FC<Props> = ({ resource, ...rest }) => (
  <MyChip
    resource={resource}
    resourceName="allocations"
    avatarCharacter="AL"
    {...rest}
  />
);

export const DeliveryChip: React.FC<Props> = ({ resource, ...rest }) => (
  <MyChip
    resource={resource}
    resourceName="deliveries"
    avatarCharacter="DL"
    {...rest}
  />
);

export const ProductInstanceChip: React.FC<{ resource: ProductInstance }> = ({
  resource,
  ...rest
}) => {
  const backgroundColor = get(
    resource,
    "product.business_unit.color_hex",
    "#aaa"
  );

  return (
    <MyChip
      resource={resource}
      resourceName="product-instances"
      avatarCharacter="ST"
      style={{ backgroundColor }}
      {...rest}
    />
  );
};
