import { Box, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useMutation, useNotify } from "ra-core";
import React from "react";
import { Delivery } from "../../../types";
import { deliveryActions } from "../util";

interface Props {
  record: Delivery;
}
const CannotPickDialog: React.FC<Props> = (props) => {
  const { record } = props;
  const [open, setOpen] = React.useState(false);
  const notify = useNotify();
  const [reason, setReason] = React.useState("");

  const handleClose = () => setOpen(false);

  const [handleAccept] = useMutation(
    {
      type: "update",
      resource: "deliveries",
      payload: {
        id: record.id ?? "",
        data: {
          action: deliveryActions.CANNOT_START,
          issue_note: reason,
        },
      },
    },
    {
      onSuccess: () => {
        notify(`The Delivery has been marked as cannot be picked.`, "success");
        setOpen(false);
      },
      onFailure: (error) => {
        notify(`Error: ${error.message}`, "error");
        setOpen(true);
      },
    }
  );

  return (
    <>
      <Button color="primary" onClick={() => setOpen(true)} size={"small"}>
        Cannot Start
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle id="form-dialog-title">Got Issue</DialogTitle>
        <DialogContent>
          <DialogContentText>
            There's an issue to start Delivery. For example, if the hospital
            does not want to release the item. This action will cancel the
            Delivery and Warehouse needs to allocate new Box in the Allocation.
            Confirm?
            <Box py="5px">
              <TextField
                label="Reason"
                fullWidth
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAccept} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CannotPickDialog;
