import { Button, MenuItem, Select, TextField } from "@material-ui/core";
import { get } from "lodash";
import { InputProps, useInput, useQueryWithStore } from "ra-core";
import { FC, useEffect, useState } from "react";
import { useField } from "react-final-form";

export interface ChoiceProps {
  id: string;
  name: string;
}
interface Props extends InputProps {
  source: string;
  textSource: string;
  label: string;
  choices: {
    resource: string;
    optionValue: string;
    optionString: string;
  };
}
const SelectOrTextInput: FC<Props> = (props) => {
  const { source, choices, textSource, label } = props;
  const { resource, optionString, optionValue } = choices;

  const [existingResource, setExistingResource] = useState(true);

  const { input } = useInput(props);

  useEffect(() => console.log("input :>> ", input), [input]);

  const { data } = useQueryWithStore({
    type: "getList",
    resource,
    payload: {
      pagination: { perPage: 1000 },
      sort: { field: optionString, order: "asc" },
    },
  });
  const [list, setList] = useState<ChoiceProps[]>();
  useEffect(() => {
    if (!data) return;

    setList(
      data.map((item: any) => ({
        id: get(item, optionValue),
        name: get(item, optionString),
      }))
    );
  }, [data, optionString, optionValue]);

  const {
    input: { onChange: onChangeText },
  } = useField(textSource);

  const {
    input: { onChange: onChangeExisting },
  } = useField(source);

  // update the text value when existingResource flag changes
  useEffect(() => {
    if (existingResource) {
      onChangeText(null);
    }
  }, [existingResource, onChangeText]);

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {existingResource ? (
          <Select
            fullWidth
            label={`Select Existing ${label}`}
            onChange={onChangeExisting}
          >
            {list?.map((item) => (
              <MenuItem value={item.id}>{item.name}</MenuItem>
            ))}
          </Select>
        ) : (
          <TextField
            name={textSource}
            onChange={onChangeText}
            fullWidth
            label={`Enter New ${label}`}
          />
        )}
        <Button
          onClick={() => setExistingResource(!existingResource)}
          variant="contained"
          size="small"
          style={{ minWidth: 150, margin: 10 }}
        >
          {existingResource ? "New" : "Select Existing"}
        </Button>
      </div>
    </div>
  );
};

export default SelectOrTextInput;
