/* eslint-disable import/no-anonymous-default-export */
import { apiUrl, getUser, httpClient } from "./dataProvider";
import {
  subscribeToPrivateChannel,
  unsubscribeFromPrivateChannel,
} from "./utils/echo";

export default {
  login: ({ username, password }: any) => {
    const request = new Request(`${apiUrl}/auth/login`, {
      method: "POST",
      body: JSON.stringify({ email: username, password: password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({ jwt }) => {
        // then read it's payload
        const payload = parseJwt(jwt);

        // if user is not admin, throw a 401 error.
        const { roles } = payload;
        if (!roles || !roles.find((role: string) => role === "admin")) {
          throw new Error("User is not allowed");
        }

        // store  jwt to localstorage
        localStorage.setItem("token", jwt);
        return payload;
      })
      .then(({ sub, roles }) => {
        // set the properties of the app,
        // based on the payload.
        localStorage.setItem("me", sub);
        localStorage.setItem("roles", JSON.stringify(roles));

        // decide the role of the user. Then, subscribe to
        // respective channel in Laravel Socket via Echo
        subscribeToPrivateChannel();

        return Promise.resolve();
      });
  },
  logout: (params: any) => {
    httpClient(`${apiUrl}/auth/logout`, {
      method: "POST",
      ...getUser(),
    });

    localStorage.removeItem("token");
    localStorage.removeItem("me");
    localStorage.removeItem("roles");
    unsubscribeFromPrivateChannel();

    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem("token") ? Promise.resolve() : Promise.reject(),
  checkError: (error: any) => {
    const status = error.status;

    if (status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("me");
      localStorage.removeItem("roles");
      unsubscribeFromPrivateChannel();
      return Promise.reject();
    }

    if (status === 403) {
      console.error(
        "User is not authorised to view some resources in this page"
      );
    }

    return Promise.resolve();
  },

  getPermissions: (params: any) => {
    const roles = localStorage.getItem("roles");
    if (roles) {
      return Promise.resolve(JSON.parse(roles));
    }

    return Promise.reject();
  },
};

/**
 * A function to parse JWT to get the paylod
 *
 * @param token JWT string
 */
const parseJwt = (token: string) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};
