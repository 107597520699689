/* eslint-disable react-hooks/exhaustive-deps */
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import LockIcon from "@material-ui/icons/Lock";
import Icon from "@material-ui/icons/ReportProblem";
import { addDays, parseISO, startOfToday } from "date-fns";
import { format, parse } from "date-fns/esm";
import { flatten, get } from "lodash";
import { useMutation, useQuery, useQueryWithStore } from "ra-core";
import React from "react";
import { Link } from "react-router-dom";
import { BookingCard } from "../components/cards";
import { Allocation, BusinessUnit, Hospital, ProductInstance } from "../types";
import { convertJsDateToMySqlDate } from "../utils/date";
import { AllocationStatus } from "./allocations/util";
import FilterDrawer from "./productInstances/FilterDrawer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    container: {
      display: "flex",
      width: "100%",
    },
    columns: {
      border: "1px solid #aaa",
      margin: "2px",
      padding: "2px",
    },
    hospitalColumnTitle: {
      borderBottom: "1px solid #aaa",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    table: {
      minWidth: 650,
      "& .MuiTableCell-root": {
        border: "1px solid rgba(224, 224, 224, 1)",
      },
    },
    filter: {
      "& .MuiButtonBase-root": {
        padding: "0 0 0 8px",
      },
      "& .MuiFormControl-root": {
        margin: "1rem 0",
      },
      "& .MuiFormLabel-root": {
        fontWeight: "bold",
        textTransform: "uppercase",
        margin: "0.7rem 0",
      },
      // fontSize: "0.8rem",
      // "& .MuiTypography-body1": {
      //   fontSize: "0.8rem",
      // }
      paddingLeft: 8,
    },
    controlContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      marginBottom: 10,
    },
  })
);

interface Query<T> {
  data?: T[];
}
const ProductInstanceBoard: React.FC<any> = () => {
  const classes = useStyles();

  // variable for FILTERS
  const [dateFrom, setDateFrom] = React.useState<string>();
  const [dateTo, setDateTo] = React.useState<string>();
  const [dates, setDates] = React.useState<Date[]>([]);
  const [showAllocations, setShowAllocations] = React.useState(false);
  const [showCurrentLocations, setShowCurrentLocations] = React.useState(true);
  const [search, setSearch] = React.useState("");
  const [allocations, setAllocations] = React.useState<Allocation[]>([]);
  const [instances, setInstances] = React.useState<ProductInstance[]>([]);
  const [showOnlyWithIssues, setShowOnlyWithIssues] = React.useState(false);
  const [showOnlyConsignments, setShowOnlyConsignments] = React.useState(false);
  const [selectedHospitals, setSelectedHospitals] = React.useState<Hospital[]>(
    []
  );
  const { data: allHospitals = [] }: Query<Hospital> = useQuery({
    type: "getList",
    resource: "hospitals",
    payload: {
      sort: { field: "name", order: "asc" },
      pagination: { page: 1, perPage: 1000 },
    },
  });
  // BU is business units
  const [selectedBUs, setSelectedBUs] = React.useState<BusinessUnit[]>([]);
  const { data: allBUs = [] }: Query<BusinessUnit> = useQuery({
    type: "getList",
    resource: "business-units",
    payload: {
      sort: { field: "name", order: "asc" },
      pagination: { page: 1, perPage: 1000 },
    },
  });

  // EFFECTS
  // load main data (allocations and product instances)
  // Product instances are loaded all and one time.
  // while allocations are loaded based on start/end date.
  const { data: allInstances } = useQueryWithStore({
    type: "getList",
    resource: "product-instances",
    payload: {
      pagination: { page: 1, perPage: 1000 },
    },
  });
  const [getAllocations, { data: allAllocations }] = useMutation({
    type: "getList",
    resource: "allocations",
    payload: {
      filter: {
        status: AllocationStatus.ASSIGNED,
        start_time_from: dateFrom,
        start_time_to: dateTo,
      },
      pagination: { page: 1, perPage: 1000 },
    },
  });

  // Select all business unit when allBus has finished loading
  React.useEffect(() => setSelectedBUs(allBUs), [allBUs]);

  // can only process the search if search string is more than 5.
  const canSearch = () => search.length > 3;

  /**
   * A helper function to check if a string is in the product instance's name
   *
   * @param needle
   * @param haystack
   * @returns
   */
  const isFound = (needle: string, haystack?: ProductInstance): boolean => {
    if (!haystack) return false;
    const hU = haystack.name.toUpperCase();
    const nU = needle.toUpperCase();
    const res = hU.indexOf(nU);
    if (res >= 0) {
      console.log(hU, nU, res);
    }

    return res >= 0;

    // return haystack.name.toUpperCase().indexOf(needle.toUpperCase()) > 0;
  };

  // At the beginning and when allHospitals finish querying,
  // set selectedHospitals to be allHospitals
  React.useEffect(() => setSelectedHospitals(allHospitals), [allHospitals]);

  /**
   * Filter allocations based on:
   * first, filter by whether it has open issues or not
   * then, filter by hospital
   * then, by business unit
   *
   * @returns Allocation[]
   */
  const filterAllocations = () => {
    if (!allAllocations) return [];
    if (!showAllocations) return [];

    let filtered = allAllocations;
    if (showOnlyWithIssues) {
      filtered = filtered.filter((allocation: Allocation) => {
        if (!allocation.product_instance) return false;
        if (!allocation.product_instance.open_issues) return false;
        if (allocation.product_instance.open_issues.length === 0) return false;
        return true;
      });
    }

    if (showOnlyConsignments) {
      filtered = filtered.filter((allocation: Allocation) => {
        if (!allocation.product_instance) return false;
        return allocation.product_instance.is_consigned;
      });
    }

    // filter by hospital
    const hospitalIds = flatten(selectedHospitals.map((item) => item.id));
    filtered = filtered.filter((allocation: Allocation) => {
      if (!allocation.booking.hospital.id) return false;
      return hospitalIds.includes(allocation.booking.hospital.id);
    });

    // filter by business unit
    const unitIds = flatten(selectedBUs.map((item) => item.id));
    filtered = filtered.filter((allocation: Allocation) => {
      if (!allocation.product_instance) return false;
      return unitIds.includes(
        allocation.product_instance.product.business_unit_id
      );
    });

    // filter by search
    if (canSearch()) {
      filtered = filtered.filter((allocation: Allocation) =>
        isFound(search, allocation.product_instance)
      );
    }
    return filtered;
  };
  React.useEffect(
    () => setAllocations(filterAllocations()),
    [
      allAllocations,
      showOnlyWithIssues,
      selectedBUs,
      selectedHospitals,
      search,
      showAllocations,
      showOnlyConsignments,
    ]
  );

  const filterInstances = () => {
    if (!allInstances) return [];
    if (!showCurrentLocations) return [];

    let filtered = allInstances;
    if (showOnlyWithIssues) {
      filtered = filtered.filter((item: ProductInstance) => {
        if (!item.open_issues) return false;
        if (item.open_issues.length === 0) return false;
        return true;
      });
    }

    if (showOnlyConsignments) {
      filtered = filtered.filter((item: ProductInstance) => item.is_consigned);
    }

    // filter by business unit
    const unitIds = flatten(selectedBUs.map((item) => item.id));
    filtered = filtered.filter((item: ProductInstance) => {
      return unitIds.includes(item.product.business_unit_id);
    });

    // filter by search
    if (canSearch()) {
      filtered = filtered.filter((item: ProductInstance) =>
        isFound(search, item)
      );
    }
    return filtered;
  };
  React.useEffect(
    () => setInstances(filterInstances()),
    [
      allInstances,
      showOnlyWithIssues,
      selectedBUs,
      selectedHospitals,
      search,
      showCurrentLocations,
      showOnlyConsignments,
    ]
  );

  //set default date on today to the next 7 days
  React.useEffect(() => {
    const start = startOfToday();
    setDateFrom(convertJsDateToMySqlDate(start));
    setDateTo(convertJsDateToMySqlDate(addDays(start, 7)));
  }, []);

  // enumerate the dates when dateFrom/to change
  React.useEffect(() => {
    if (!dateFrom || !dateTo) {
      return;
    }

    const from = parse(dateFrom, "yyyy-MM-dd", new Date());
    const LIMIT_DAYS = 1000;
    let allDates: Date[] = [];
    let i = 0;
    for (i = 0; i <= LIMIT_DAYS; i++) {
      const jsDate = addDays(from, i);
      allDates.push(jsDate);

      const mysqlDate = convertJsDateToMySqlDate(jsDate);
      if (mysqlDate === dateTo) {
        break;
      }
    }
    setDates(allDates);
  }, [dateFrom, dateTo]);

  // get Allocations when the dateFrom/to change
  React.useEffect(() => {
    if (!dateFrom || !dateTo) {
      return;
    }
    getAllocations();
  }, [dateFrom, dateTo, getAllocations]);

  const parseDateFromSqlToJs = (date: string): Date =>
    parse(date, "yyyy-MM-dd", new Date());

  const handleDateFromUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDateFrom = event.target.value;
    setDateFrom(newDateFrom);
    const parsedDateFrom = parseDateFromSqlToJs(newDateFrom);
    setDateTo(convertJsDateToMySqlDate(addDays(parsedDateFrom, 7)));
  };

  return (
    <Box style={{ width: "100%" }}>
      <h1>Set and Allocation Board</h1>
      <Grid container spacing={1}>
        <Grid item xs={12} style={{ height: "90vh", overflowY: "scroll" }}>
          <Box>
            <div className={classes.controlContainer}>
              <FilterDrawer>
                <SideFilter
                  allHospitals={allHospitals}
                  selectedHospitals={selectedHospitals}
                  setSelectedHospitals={setSelectedHospitals}
                  showAllocations={showAllocations}
                  setShowAllocations={setShowAllocations}
                  showCurrentLocations={showCurrentLocations}
                  setShowCurrentLocations={setShowCurrentLocations}
                  dateFrom={dateFrom}
                  updateDateFrom={handleDateFromUpdate}
                  allBusinessUnits={allBUs}
                  selectedBusinessUnits={selectedBUs}
                  setSelectedBusinessUnits={setSelectedBUs}
                  showOnlyWithIssues={showOnlyWithIssues}
                  setShowOnlyWithIssues={setShowOnlyWithIssues}
                  showOnlyConsignments={showOnlyConsignments}
                  setShowOnlyConsignments={setShowOnlyConsignments}
                />
              </FilterDrawer>
              <TextField
                label="Search Set"
                variant="outlined"
                style={{ width: 400 }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                size="small"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    e.stopPropagation();
                    e.preventDefault();
                    return;
                  }
                }}
              />
            </div>
            <Table className={classes.table}>
              <TableHead>
                <HospitalTableRow hospitals={selectedHospitals} />
              </TableHead>
              <TableBody>
                {showCurrentLocations && (
                  <InstanceBodyRow
                    hospitals={selectedHospitals}
                    instances={instances}
                  />
                )}

                {/* Show Allocation Rows based on days */}
                {showAllocations &&
                  dates.map((date) => (
                    <AllocationBodyRow
                      hospitals={selectedHospitals}
                      date={date}
                      allocations={allocations ?? []}
                    />
                  ))}
              </TableBody>
            </Table>
          </Box>
          {/* <Box className={classes.container}>
        {hospitals.map((hospital) => (
          <Column hospital={hospital} allocations={allocations} />
        ))}
      </Box> */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductInstanceBoard;

/**
 * Table
 */

interface TableProps {
  hospitals: Hospital[];
}
interface AllocationBodyRowProps extends TableProps {
  date: Date;
  allocations: Allocation[];
}
interface InstanceBodyRowProps extends TableProps {
  instances: ProductInstance[];
}

const HospitalTableRow: React.FC<TableProps> = ({ hospitals }) => {
  return (
    <TableRow
      style={{
        fontWeight: "bold",
        backgroundColor: "#333",
      }}
    >
      <TableCell
        style={{
          minWidth: 150,
          color: "#eee",
          textAlign: "center",
        }}
      >
        Date
      </TableCell>
      <TableCell
        style={{
          minWidth: 150,
          color: "#eee",
          textAlign: "center",
        }}
      >
        MLS
      </TableCell>

      {hospitals.map((hospital) => (
        <TableCell
          key={hospital.id}
          style={{ minWidth: 150, color: "#fff", textAlign: "center" }}
        >
          {hospital.name}
        </TableCell>
      ))}
    </TableRow>
  );
};

const AllocationBodyRow: React.FC<AllocationBodyRowProps> = (props) => {
  const { hospitals, date, allocations } = props;
  const [thisDateAllocations, setThisDateAllocations] = React.useState<
    Allocation[]
  >([]);

  // filter the allocations to show this "date" only
  React.useEffect(() => {
    const filtered = allocations.filter((allocation) => {
      if (!allocation.start_time) {
        return false;
      }
      const startTime = parseISO(allocation.start_time);
      return (
        convertJsDateToMySqlDate(startTime) === convertJsDateToMySqlDate(date)
      );
    });
    setThisDateAllocations(filtered);
  }, [allocations, date]);

  //   const filtered = allocations.filter();
  // }, []);

  /**
   * Filter Allocations based on hospital and date.
   *
   * @param allocations
   * @param hospital
   * @param date
   * @returns
   */
  const filteredAllocations = (
    allocations: Allocation[],
    hospital: Hospital,
    date: Date
  ): Allocation[] => {
    const filtered = allocations
      .filter((allocation) => allocation.booking?.hospital.id === hospital.id)
      .filter((allocation) => {
        if (!allocation.start_time) {
          return false;
        }
        const startTime = parseISO(allocation.start_time);
        return (
          convertJsDateToMySqlDate(startTime) === convertJsDateToMySqlDate(date)
        );
      });
    return filtered;
  };

  const showDateColumn = (date: Date) => format(date, "EEE, dd LLL u");

  return (
    <TableRow>
      <TableCell>{showDateColumn(date)}</TableCell>
      {/* For the warehouse */}
      <TableCell></TableCell>
      {hospitals.map((hospital) => (
        <TableCell>
          {filteredAllocations(thisDateAllocations, hospital, date).map(
            (allocation) => {
              // if (allocation.product_instance) {
              return (
                // <Box>
                <AllocationSticker allocation={allocation} />
                // </Box>
                // <InstanceSticker instance={allocation.product_instance} />
              );
              // }
            }
          )}
        </TableCell>
      ))}
    </TableRow>
  );
};

/**
 * A helper function to group product instances to Hospitals
 *
 * @param hospitals
 * @param instances
 * @returns {...Hospital, instances: ProductInstance[]}
 */
const groupInstancesToHospital = (
  hospitals: Hospital[],
  instances: ProductInstance[]
) => {
  // foreach hospital rooms, filter the instances
  // which instance.location_id == room.id
  return hospitals.map((hospital) => {
    const inRooms = hospital.rooms.map((room) =>
      instances.filter((instance) => instance.location_id === room.location_id)
    );

    // flatten and sort
    const flattened = flatten(inRooms).sort((a, b) =>
      a.name.localeCompare(b.name)
    );

    return {
      ...hospital,
      instances: flattened,
    };
  });
};
const InstanceBodyRow: React.FC<InstanceBodyRowProps> = (props) => {
  const { hospitals, instances } = props;

  const hospitalWithInstances = groupInstancesToHospital(hospitals, instances);

  const warehouseLocationId = get(
    process.env,
    "REACT_APP_MLS_WAREHOUSE_LOCATION_ID",
    ""
  );
  const instancesInWarehouse = instances.filter(
    (instance) => instance.location_id === parseInt(warehouseLocationId)
  );

  return (
    <TableRow>
      <TableCell style={{ verticalAlign: "top" }}>Current Location</TableCell>
      {/* Show product instances in the warehouse */}
      <TableCell style={{ verticalAlign: "top" }}>
        {instancesInWarehouse.map((instance) => (
          <InstanceSticker instance={instance} />
        ))}
      </TableCell>
      {hospitalWithInstances.map((hospital) => (
        <TableCell style={{ verticalAlign: "top" }}>
          <Box>
            {hospital.instances.map((instance) => (
              <InstanceSticker instance={instance} />
            ))}
          </Box>
        </TableCell>
      ))}
    </TableRow>
  );
};

const AllocationSticker: React.FC<{ allocation: Allocation }> = (props) => {
  const { allocation } = props;
  const { product_instance: instance } = allocation;
  const [showInfo, setShowInfo] = React.useState(false);
  if (!instance) {
    return <></>;
  }

  return (
    <Box
      key={allocation.id}
      style={{
        cursor: "pointer",
        position: "relative",
        display: "inline-block",
      }}
      onMouseEnter={() => setShowInfo(true)}
      onMouseLeave={() => setShowInfo(false)}
    >
      <InstanceSticker instance={instance} />
      {showInfo && <HoverInfo allocation={allocation} />}
    </Box>
  );
};

const InstanceSticker: React.FC<{ instance: ProductInstance }> = ({
  instance,
}) => {
  const backgroundColor = get(
    instance,
    "product.business_unit.color_hex",
    "#aaa"
  );

  return (
    <Box
      key={instance.id}
      style={{
        fontSize: "small",
        border: "1px solid #999",
        padding: "2px",
        margin: "2px",
        backgroundColor,
        color: "white",
        fontWeight: "bold",
        minWidth: 100,
        maxWidth: 150,
        cursor: "pointer",
        display: "inline-block",
      }}
    >
      <Box position="relative">
        {instance.open_issues && instance.open_issues.length > 0 && (
          <Icon
            color="error"
            style={{
              position: "absolute",
              left: -10,
              top: -10,
              backgroundColor: "rgba(255,255,255,0.5)",
            }}
          />
        )}
        <Link
          target="_blank"
          to={`/product-instances/${instance.id}/show`}
          style={{ color: "white", textDecoration: "none" }}
        >
          {instance.is_consigned && (
            <LockIcon
              style={{
                color: "yellow",
                backgroundColor: "rgba(0,0,0)",
                height: 20,
                width: 20,
                marginRight: 5,
              }}
            />
          )}
          {`(${instance.id}) ${instance.name}`}
        </Link>
      </Box>
    </Box>
  );
};

interface HoverInfoProps {
  allocation: Allocation;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}
const HoverInfo: React.FC<HoverInfoProps> = (props) => {
  const { allocation, onMouseEnter, onMouseLeave } = props;
  return (
    <Box
      style={{ position: "absolute", zIndex: 1, left: "100%", top: 0 }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <BookingCard record={allocation.booking} source="id" />
    </Box>
  );
};

interface SideFilterProps {
  allHospitals: Hospital[];
  selectedHospitals: Hospital[];
  setSelectedHospitals: any;

  showCurrentLocations: boolean;
  setShowCurrentLocations: any;
  showAllocations: boolean;
  setShowAllocations: any;
  dateFrom?: string;
  updateDateFrom?: any; // callback if datefrom is changed

  showOnlyWithIssues: boolean;
  setShowOnlyWithIssues: any;

  showOnlyConsignments: boolean;
  setShowOnlyConsignments: any;

  allBusinessUnits: BusinessUnit[];
  selectedBusinessUnits: BusinessUnit[];
  setSelectedBusinessUnits: any;
}
const SideFilter: React.FC<SideFilterProps> = (props) => {
  const classes = useStyles();
  const { allHospitals, selectedHospitals, setSelectedHospitals } = props;
  const { showCurrentLocations, setShowCurrentLocations } = props;
  const { showAllocations, setShowAllocations } = props;
  const { dateFrom, updateDateFrom } = props;
  const { allBusinessUnits, selectedBusinessUnits, setSelectedBusinessUnits } =
    props;
  const { showOnlyWithIssues, setShowOnlyWithIssues } = props;
  const { showOnlyConsignments, setShowOnlyConsignments } = props;

  const unselect = (resource: any, resources: any[], setResources: any) =>
    setResources(resources.filter((item: any) => item.id !== resource.id));

  const select = (resource: any, resources: any[], setResources: any) => {
    resources.push(resource);
    resources.sort((a, b) => a.name.localeCompare(b.name));
    setResources([...resources]);
  };

  const isSelected = (resource: any, resources: any[]): boolean => {
    const found = resources.find((item: any) => item.id === resource.id);
    return found ? true : false;
  };

  const unselectHospital = (hospital: Hospital) =>
    unselect(hospital, selectedHospitals, setSelectedHospitals);

  const selectHospital = (hospital: Hospital) =>
    select(hospital, selectedHospitals, setSelectedHospitals);

  const isHospitalSelected = (hospital: Hospital) =>
    isSelected(hospital, selectedHospitals);

  const unselectBu = (bu: BusinessUnit) =>
    unselect(bu, selectedBusinessUnits, setSelectedBusinessUnits);

  const selectBu = (bu: BusinessUnit) =>
    select(bu, selectedBusinessUnits, setSelectedBusinessUnits);

  const isBuSelected = (bu: BusinessUnit) =>
    isSelected(bu, selectedBusinessUnits);

  // const unselectHospital = (hospital: Hospital) => {
  //   setSelectedHospitals(
  //     selectedHospitals.filter((item) => item.id !== hospital.id)
  //   );
  // };

  // const selectHospital = (hospital: Hospital) => {
  //   selectedHospitals.push(hospital);
  //   selectedHospitals.sort((a, b) => a.name.localeCompare(b.name));
  //   setSelectedHospitals([...selectedHospitals]);
  // };

  return (
    <Box className={classes.filter}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Type</FormLabel>

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={showCurrentLocations}
                onChange={(e) => setShowCurrentLocations(e.target.checked)}
              />
            }
            label="Show Current Location "
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={showAllocations}
                onChange={(e) => setShowAllocations(e.target.checked)}
              />
            }
            label="Show Allocations Table"
          />
        </FormGroup>
      </FormControl>

      <FormControlLabel
        control={
          <Checkbox
            checked={showOnlyWithIssues}
            onChange={(e) => setShowOnlyWithIssues(e.target.checked)}
          />
        }
        label="With Open Issues Only"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={showOnlyConsignments}
            onChange={(e) => setShowOnlyConsignments(e.target.checked)}
          />
        }
        label="Consignments Only"
      />

      <TextField
        id="date"
        label="Date From"
        type="date"
        value={dateFrom}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={updateDateFrom}
      />

      <FormControl component="fieldset">
        <FormLabel component="legend">Hospitals</FormLabel>

        <Box style={{ margin: "0.5rem 0" }}>
          <Button
            onClick={() => setSelectedHospitals(allHospitals)}
            size="small"
          >
            Select All
          </Button>
          <Button onClick={() => setSelectedHospitals([])} size="small">
            Unselect All
          </Button>
        </Box>
        <FormGroup>
          {allHospitals.map((hospital) => (
            <FormControlLabel
              key={hospital.id}
              control={
                <Checkbox
                  onChange={(e) =>
                    e.target.checked
                      ? selectHospital(hospital)
                      : unselectHospital(hospital)
                  }
                />
              }
              label={hospital.name}
              checked={isHospitalSelected(hospital)}
            />
          ))}
        </FormGroup>
      </FormControl>

      <FormControl component="fieldset">
        <FormLabel component="legend">Business Unit</FormLabel>

        <Box style={{ margin: "0.5rem 0" }}>
          <Button
            onClick={() => setSelectedBusinessUnits(allBusinessUnits)}
            size="small"
          >
            Select All
          </Button>
          <Button onClick={() => setSelectedBusinessUnits([])} size="small">
            Unselect All
          </Button>
        </Box>

        <FormGroup>
          {allBusinessUnits.map((bu) => (
            <FormControlLabel
              key={bu.id}
              control={
                <Checkbox
                  onChange={(e) =>
                    e.target.checked ? selectBu(bu) : unselectBu(bu)
                  }
                />
              }
              label={bu.name}
              checked={isBuSelected(bu)}
            />
          ))}
        </FormGroup>
      </FormControl>
    </Box>
  );
};
