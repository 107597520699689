import Icon from "@material-ui/icons/PinDrop";
import { FC } from "react";
import {
  BooleanField,
  BooleanInput,
  Create,
  CreateProps,
  Datagrid,
  Edit,
  EditButton,
  EditProps,
  Filter,
  FunctionField,
  List,
  ListButton,
  ListProps,
  SaveButton,
  SelectField,
  SelectInput,
  Show,
  ShowProps,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
} from "react-admin";
import CrudBreadcrumb from "../components/CrudBreadcrumb";
import StatusField from "../components/StatusField";

const ResourceList: FC<ListProps> = (props) => (
  <List
    {...props}
    exporter={false}
    filters={<ListFilter />}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="show">
      <TextField source="id" label="Location ID" />
      <TextField source="name" />
      <FunctionField
        label="Virtual"
        render={(record: any) => (record.is_virtual === true ? "Yes" : "No")}
      />
      <StatusField source="status" />
    </Datagrid>
  </List>
);

const ListFilter: FC<any> = (props) => (
  <Filter {...props}>
    <TextInput source="id" label="ID" />
    <TextInput source="search" label="Name" />
    <SelectInput
      source="is_virtual"
      choices={[
        { id: true, name: "Yes" },
        { id: false, name: "No" },
      ]}
    />
    <SelectInput
      source="status"
      choices={[
        { id: "active", name: "active" },
        { id: "inactive", name: "inactive" },
      ]}
    />
  </Filter>
);

const ResourceShow: FC<ShowProps> = (props) => {
  return (
    <>
      <CrudBreadcrumb
        resourceName={Common.resourceName}
        resourceId={props.id}
        mode="show"
      />
      <Show {...props} actions={<ShowActions />}>
        <SimpleShowLayout>
          <TextField source="id" label="ID" />
          <TextField source="name" />
          <BooleanField source="is_virtual" />
          <SelectField
            source="status"
            choices={[
              { id: "active", name: "Active" },
              { id: "inactive", name: "Inactive" },
            ]}
          />
        </SimpleShowLayout>
      </Show>
    </>
  );
};

const ShowActions: FC<any> = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const LocationEditToolbar: FC<any> = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);

const ResourceEdit: FC<EditProps> = (props) => {
  return (
    <>
      <CrudBreadcrumb
        resourceName={Common.resourceName}
        resourceId={props.id}
        mode="edit"
      />
      <Edit {...props}>
        <SimpleForm redirect="show" toolbar={<LocationEditToolbar />}>
          <TextField source="id" />
          <TextInput source="name" />
          <BooleanInput source="is_virtual" />
          <SelectInput
            source="status"
            choices={[
              { id: "active", name: "Active" },
              { id: "inactive", name: "Inactive" },
            ]}
          />
        </SimpleForm>
      </Edit>
    </>
  );
};

const ResourceCreate: FC<CreateProps> = (props) => {
  return (
    <>
      <CrudBreadcrumb
        resourceName={Common.resourceName}
        resourceId={""}
        mode="create"
      />
      <Create {...props}>
        <SimpleForm>
          <TextInput source="name" />
          <TextInput source="lat" />
          <TextInput source="lon" />
          <SelectInput
            source="is_virtual"
            choices={[
              { id: false, name: "No" },
              { id: true, name: "Yes" },
            ]}
          />
        </SimpleForm>
      </Create>
    </>
  );
};
const Common = {
  resourceName: "locations",
};
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: "locations",
  icon: Icon,
  list: ResourceList,
  show: ResourceShow,
  edit: ResourceEdit,
  create: ResourceCreate,
};
