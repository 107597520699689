import React from "react";
import { Allocation } from "../../../types";
import { AllocationActions } from "../util";
import ActionProps from "./AllocationActionProps";
import ConfirmationButton from "./ConfirmationButton";

const GotIssueButton: React.FC<ActionProps<Allocation>> = (props) => {
  const { data: allocation } = props;

  const dialogContent = `Mark the allocation as having an issue. The corresponding Delivery ID ${
    allocation.delivery_id ?? ""
  } will be suspended. Confirm?`;

  return (
    <ConfirmationButton
      data={allocation}
      action={AllocationActions.ADD_ISSUE}
      buttonText={`Got Issue`}
      dialogTitle={`Mark Assigment as Having an Issue`}
      dialogContent={dialogContent}
      confirmationButtonText={`Confirm`}
    />
  );
};

export default GotIssueButton;
