import Chip from "@material-ui/core/Chip";
import get from "lodash/get";
import React from "react";
import {
  AllocationStatus,
  AllocationStatusColor,
} from "../pages/allocations/util";
import {
  BookingStatus,
  IssueStatus,
  StatusColor,
} from "../pages/bookings/util";
import { getStyle } from "../utils";
import { UserStatus } from "../utils/const";
import { StatusColorContext } from "./contexts";

export const translateStatus = (status?: string): string => {
  if (!status) {
    return "";
  }

  return status
    .replace("STATUS_", "")
    .replace(/_/g, " ") // replace all _ in status, i.e. ASSIGNMENT_SUCCESS --> ASSIGNMENT SUCCESS
    .toUpperCase();
};

/**
 * A helper function to generate the class, given a status
 * @param status
 * @returns
 */
export const getClass = (status: string) => {
  switch (status.toLowerCase()) {
    // green
    case UserStatus.ACTIVE:
    case BookingStatus.NEW:
      return { backgroundColor: StatusColor.NEW, color: "white" };

    // yellow
    case BookingStatus.DELIVERED:
    case IssueStatus.OPEN:
      return { backgroundColor: StatusColor.DELIVERED, color: "white" };

    // blue
    case BookingStatus.ALLOCATED:
      return { backgroundColor: StatusColor.ALLOCATED, color: "white" };

    // purple
    case BookingStatus.ACCEPTED:
      return { backgroundColor: StatusColor.ACCEPTED, color: "white" };

    // grey
    case UserStatus.INACTIVE:
      return { backgroundColor: StatusColor.INACTIVE, color: "#333" };

    case AllocationStatus.ASSIGNED:
      return {
        backgroundColor: AllocationStatusColor.ASSIGNED,
        color: "white",
      };

    case AllocationStatus.UNASSIGNED:
      return {
        backgroundColor: AllocationStatusColor.UNASSIGNED,
        color: "white",
      };

    case AllocationStatus.GOT_ISSUE:
      return {
        backgroundColor: AllocationStatusColor.GOT_ISSUE,
        color: "white",
      };

    // case AllocationStatus.UNASSIGNED:
    //   return {
    //     backgroundColor: AllocationStatusColor.UNASSIGNED,
    //     color: "white",
    //   };

    case AllocationStatus.DONE:
      return { backgroundColor: AllocationStatusColor.DONE, color: "white" };

    case AllocationStatus.CANCELLED:
      return {
        backgroundColor: AllocationStatusColor.CANCELLED,
        color: "white",
      };

    // red
    // case "red":
    //   return  { backgroundColor: "#bd0404" };

    default:
      return { backgroundColor: StatusColor.DEFAULT, color: "#eee" };
    //default color is used at Status Canceled, Surgery Started,
    //Driver Assigned, Picked, Close, Report Submitted and Invoiced
  }
};

interface IStatusField {
  label?: string;
  record?: any;
  source: string;
  size?: "small";
}
const StatusField = ({ label, record = {}, source, size }: IStatusField) => {
  const status = get(record, source);
  const translated = translateStatus(status);
  const { colors } = React.useContext(StatusColorContext);

  return (
    <Chip
      label={translated}
      // size="small"
      style={{ ...getStyle(status, colors), minWidth: "100px" }}
    />
  );
};

StatusField.defaultProps = {
  label: "Status",
  addLabel: true,
};

export default StatusField;
