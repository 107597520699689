import { stringify } from "query-string";
import { apiUrl, getUser, httpClient } from "../dataProvider";

interface Get {
  json: any;
  status: number;
}

export const getUnreadInboxItemsCount = (): Promise<Get> => {
  let query: any = {};
  query["filter"] = JSON.stringify({
    is_unread: true,
    read_at: null,
  });
  const url = `${apiUrl}/inboxes?${stringify(query)}`;
  return httpClient(url, getUser()).then(({ json, status }: any) => {
    return { json, status };
  });
};
