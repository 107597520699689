import Icon from "@material-ui/icons/PermPhoneMsg";
import { BookingCreate } from "./BookingCreate";
import { BookingEdit } from "./BookingEdit";
import { BookingList } from "./BookingList";
import { BookingShow } from "./BookingShow";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: "bookings",
  icon: Icon,
  list: BookingList,
  show: BookingShow,
  create: BookingCreate,
  edit: BookingEdit,
};
