import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  closeContainer: {
    cursor:'pointer',
    textAlign:'right',
    paddingRight:11
  }, 
  button:{
    marginRight:5
  }
});

interface FilterI {
  children?:JSX.Element
}

 const  FilterDrawer:React.FC<FilterI> = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState<boolean>(false);

  const toggleDrawer = (newState: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState( newState );
  };

  const content = () => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: false,
      })}
      role="presentation"
    >
      <p className={classes.closeContainer}
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <CloseIcon />
      </p>
      {
        typeof props.children !== 'undefined' &&
        props.children
      }
      
    </div>
  );

  return (
    <>
      <Button variant="outlined" startIcon={<FilterListIcon />} className={classes.button}
        onClick={toggleDrawer(true)}
      >
          Filters
      </Button>
      <SwipeableDrawer
        anchor={'left'}
        open={state}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {content()}
      </SwipeableDrawer>
    </>
  );
}
//

export default FilterDrawer;
