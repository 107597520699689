import React from "react";
import { Box } from "@material-ui/core";

const About: React.FC<void> = () => {
  return (
    <Box>
      <h1>About MLS Case Booking</h1>
      <Box>Version: 1.0</Box>
    </Box>
  );
};

export default About;
