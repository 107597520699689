import { get } from "lodash";
import React from "react";
import { FieldProps } from "./types";

interface Props extends Omit<FieldProps, "source"> {
  source?: string;
  children: JSX.Element;
}
const IdField: React.FC<Props> = (props) => {
  const { record, source } = props;
  const { children } = props;
  const resource = source ? get(record, source) : record;

  if (!resource) {
    return <span></span>;
  }

  return React.cloneElement(children, { resource });
};

IdField.defaultProps = {
  label: "ID",
  addLabel: true,
};

export default IdField;
