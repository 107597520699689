import { Theme, useMediaQuery } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import { createStyles, withStyles } from "@material-ui/core/styles";
import ReportIcon from "@material-ui/icons/Assignment";
import InfoIcon from "@material-ui/icons/Info";
import ParentMenuIcon from "@material-ui/icons/KeyboardArrowRight";
import { get } from "lodash";
import { ReduxState } from "ra-core";
import React, { FC, useState } from "react";
import { MenuItemLink } from "react-admin";
import { useSelector } from "react-redux";
import useFinance from "../../hooks/useFinance";
import admins from "../../pages/admins";
import allocations from "../../pages/allocations";
import bookings from "../../pages/bookings";
import colors from "../../pages/colors";
import consultants from "../../pages/consultants";
import deliveries from "../../pages/deliveries";
import drivers from "../../pages/drivers";
import hospitalRooms from "../../pages/hospitalRooms";
import hospitals from "../../pages/hospitals";
import inboxes from "../../pages/inboxes";
import issues from "../../pages/issues";
import locations from "../../pages/locations";
import procedures from "../../pages/procedures";
import productInstances from "../../pages/productInstances";
import products from "../../pages/products";
import settings from "../../pages/settings";
import surgeons from "../../pages/surgeons";
import templates from "../../pages/templates";
import { getUnreadInboxItemsCount } from "../../utils/inbox";
import SubMenu from "./SubMenu";

type SubMenuName = "users" | "masterData";

export type ThemeName = "light" | "dark";

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px",
    },
  })
)(Badge);
interface AppState extends ReduxState {
  theme: ThemeName;
}
interface Props {
  dense: boolean;
  logout: () => void;
  onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
  const [inboxCount, setInboxCount] = React.useState(0);
  const [subMenus, setSubmenus] = useState({
    users: false,
    masterData: false,
  });
  const isFinance = useFinance();

  // every 30 seconds, get inbox count.
  React.useEffect(() => {
    const getInboxCount = () =>
      getUnreadInboxItemsCount().then((data: any) =>
        setInboxCount(get(data, "json.total", 0))
      );

    getInboxCount();
    const timer = setInterval(getInboxCount, 30000);

    return () => clearInterval(timer);
  }, []);

  // const translate = useTranslate();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  useSelector((state: AppState) => state.theme); // force rerender on theme change

  const handleToggleSubMenu = (toggledSubMenu: SubMenuName) => {
    setSubmenus({ ...subMenus, [toggledSubMenu]: !subMenus[toggledSubMenu] });
  };

  // Finance can only view inbox and bookings
  if (isFinance) {
    return (
      <div>
        <MenuItemLink
          to={`/inboxes`}
          primaryText={"Inbox"}
          leftIcon={
            <StyledBadge badgeContent={inboxCount} color="primary">
              <inboxes.icon />
            </StyledBadge>
          }
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />

        <MenuItemLink
          to={`/bookings`}
          primaryText={"Bookings"}
          leftIcon={<bookings.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </div>
    );
  }

  return (
    <div>
      <MenuItemLink
        to={`/inboxes`}
        primaryText={"Inbox"}
        leftIcon={
          <StyledBadge badgeContent={inboxCount} color="primary">
            <inboxes.icon />
          </StyledBadge>
        }
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      {/* <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} /> */}
      <MenuItemLink
        to={`/bookings`}
        primaryText={"Bookings"}
        leftIcon={<bookings.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        to={`/product-instances`}
        primaryText={"Product Sets"}
        leftIcon={<productInstances.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />

      <MenuItemLink
        to={`/allocations`}
        primaryText={"Allocations"}
        leftIcon={<allocations.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />
      <MenuItemLink
        to={`/deliveries`}
        primaryText={"Deliveries"}
        leftIcon={<deliveries.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />

      <MenuItemLink
        to={`/hospital-rooms`}
        primaryText={"Department"}
        leftIcon={<hospitalRooms.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />

      <MenuItemLink
        to={`/issues`}
        primaryText={"Issues"}
        leftIcon={<issues.icon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />

      <MenuItemLink
        to={`/reports`}
        primaryText={"Reports"}
        leftIcon={<ReportIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />

      <SubMenu
        handleToggle={() => handleToggleSubMenu("masterData")}
        isOpen={subMenus.masterData}
        sidebarIsOpen={open}
        name="Master Data"
        icon={<ParentMenuIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/products`}
          primaryText={"Products"}
          leftIcon={<products.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/locations`}
          primaryText={"Locations"}
          leftIcon={<locations.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/hospitals`}
          primaryText={"Hospitals"}
          leftIcon={<hospitals.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/surgeons`}
          primaryText={"Surgeons"}
          leftIcon={<surgeons.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/procedures`}
          primaryText={"Procedures"}
          leftIcon={<procedures.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/business-units`}
          primaryText={"Business Unit"}
          leftIcon={<settings.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/settings`}
          primaryText={"Settings"}
          leftIcon={<settings.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <SubMenu
          handleToggle={() => handleToggleSubMenu("users")}
          isOpen={subMenus.users}
          sidebarIsOpen={open}
          name="Users"
          icon={<ParentMenuIcon />}
          dense={dense}
        >
          <MenuItemLink
            to={`/consultants`}
            primaryText={"Consultants"}
            leftIcon={<consultants.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />

          <MenuItemLink
            to={`/drivers`}
            primaryText={"Drivers"}
            leftIcon={<drivers.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />

          <MenuItemLink
            to={`/admins`}
            primaryText={"Admins"}
            leftIcon={<admins.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
            dense={dense}
          />
        </SubMenu>

        <MenuItemLink
          to={`/templates`}
          primaryText={"Templates"}
          leftIcon={<templates.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />

        <MenuItemLink
          to={`/colors`}
          primaryText={"Colors Management"}
          leftIcon={<colors.icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>

      <MenuItemLink
        to={`/about`}
        primaryText={"About"}
        leftIcon={<InfoIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
        dense={dense}
      />

      {isXSmall && logout}
    </div>
  );
};

export default Menu;
