import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { DropzoneArea } from "material-ui-dropzone";
import { useMutation, useNotify } from "ra-core";
import React from "react";
import { Booking } from "../../types";
import { OrderActions } from "./util";

interface Props {
  data: Booking;
}

const SubmitReportDialog: React.FC<Props> = (props) => {
  const { data } = props;
  const [open, setOpen] = React.useState(false);
  const [files] = React.useState<File[]>([]);
  const [medias, setMedias] = React.useState<{ url: string; type: string }[]>(
    []
  );
  const [note, setNote] = React.useState<string>();

  const notify = useNotify();
  const [handleAccept] = useMutation(
    {
      type: "update",
      resource: "bookings",
      payload: {
        id: data.id ?? "",
        data: { action: OrderActions.SUBMIT_REPORT, medias, report_note: note },
      },
    },
    {
      onSuccess: () => {
        notify(`Successfully update the Booking.`, "info");
      },
      onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
    }
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const convertFileToBase64 = (file: File) => {
    var reader = new FileReader();
    return new Promise((resolve) => {
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        var base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const handleSelectFile = async (files: File[]) => {
    // convert file to base64
    const convertAll = async (files: File[]) =>
      Promise.all(
        files.map(async (file: File) => {
          return await convertFileToBase64(file);
        })
      );

    convertAll(files)
      .then((base64s) =>
        base64s.map((base64) => ({
          url: base64,
          type: "image",
        }))
      )
      .then((medias) => setMedias(medias as { url: string; type: string }[]));
  };

  React.useEffect(() => console.log("files :>> ", files), [files]);

  return (
    <>
      <Button
        color="primary"
        onClick={handleClickOpen}
        size={"small"}
        variant="contained"
      >
        Submit Report
      </Button>
      <Dialog open={open} onClose={handleClose} disableBackdropClick={true}>
        <DialogTitle id="alert-dialog-title">{"Submit Report"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Upload report files related to the booking.
          </DialogContentText>
          <TextField label="Note" onChange={(e) => setNote(e.target.value)} />

          <DropzoneArea onChange={handleSelectFile} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleAccept} color="primary" autoFocus>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SubmitReportDialog;
