import { makeStyles } from "@material-ui/core/styles";

const toolStyles = {
    "& section": {
      display: "flex",
    },
    "& .ra-input": {
      marginRight: "1rem",
      "&:first-child": {
        minWidth: "400px",
      },
      "&:nth-child(2) input": {
        textAlign: "right",
      },
    },
  }
  
const useStyles = makeStyles({
  root: {
    "& h2": {
      marginTop: "2rem",
    },
    "& h2::before": {
      display: "block",
      borderTop: "1px solid #ddd",
      margin: "1rem 0",
      content: '""',
    },
  },
  instrument: toolStyles,
  implants: toolStyles
});

export default useStyles;