import React, { FC, useEffect } from "react";
import {
  ReferenceField,
  Show,
  ShowProps,
  Tab,
  TabbedShowLayout,
  TextField,
  useRefresh,
} from "react-admin";
import CrudBreadcrumb from "../../components/CrudBreadcrumb";
import ProductInstanceDataGrid from "./ProductInstanceDataGrid";

const ResourceShow: FC<ShowProps> = (props) => {
  const refresh = useRefresh();

  //force refresh when component mount so sets is Department can bee seen
  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <>
      <CrudBreadcrumb
        resourceName={"hospital-rooms"}
        resourceId={props.id}
        mode="show"
      />
      <Show {...props} title={`Department #${props.id}`}>
        <TabbedShowLayout>
          <Tab label="Summary">
            <TextField source="id" />
            <TextField source="name" label="Name" />
            <ReferenceField
              label="Hospital"
              source="hospital_id"
              reference="hospitals"
            >
              <TextField source="name" />
            </ReferenceField>
          </Tab>
          <Tab label="Sets in Department">
            <ProductInstanceDataGrid />
          </Tab>
        </TabbedShowLayout>
      </Show>
    </>
  );
};

export default ResourceShow;
