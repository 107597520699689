import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Paper from "@material-ui/core/Paper";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useQueryWithStore } from "ra-core";
import { Loading } from "ra-ui-materialui";
import React from "react";
import { Allocation, Booking } from "../../types";
import BookingItems from "../bookings/BookingItems";
import DeliveryContainer from "../bookings/Deliveries";
import { BookingCard } from "../../components/cards";
import { Box, List } from "@material-ui/core";
import { ProductInstanceIdField } from "../../components/fields";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
      textTransform: "uppercase",
    },
  })
);

interface Props {
  id: number;
  record?: Booking;
}

const BookingSummary: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { id } = props;
  const { loaded, data } = useQueryWithStore({
    type: "getOne",
    resource: "bookings",
    payload: { id },
  });
  const [booking, setBooking] = React.useState<Booking>();

  React.useEffect(() => setBooking(data), [data]);

  if (!loaded) return <Loading />;
  if (!booking) return <Loading />;

  return (
    <Paper>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Booking Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BookingDetailTable booking={booking} />
        </AccordionDetails>
      </Accordion>

      {/* Booking Items */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Booked Products</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <BookingItems record={booking} />
        </AccordionDetails>
      </Accordion>

      {/* Allocations */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            Allocated Products
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AllocatedDetailTable booking={booking} />
        </AccordionDetails>
      </Accordion>

      {/* Delivery */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>Deliveries</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <DeliveryContainer record={booking} />
        </AccordionDetails>
      </Accordion>
      {/* <Box p="1rem" minHeight="400px">        
        </Box> */}
    </Paper>
  );
};
export default BookingSummary;

const BookingDetailTable: React.FC<{ booking: Booking }> = ({ booking }) => {
  console.log("test", booking);
  return (
    <List style={{ display: "flex", flexWrap: "wrap" }}>
      <Box width={450} m={1}>
        <BookingCard record={booking} source="id" />
      </Box>
    </List>
  );
};

const AllocatedDetailTable: React.FC<{ booking: Booking }> = ({ booking }) => {
  console.log("allocations", booking);

  if (booking.allocations.length === 0) {
    return (
      <Box display="flex" width="100%" height="100px" alignItems="center">
        <Box>There's no Allocation assigned to this booking yet.</Box>
      </Box>
    );
  }
  return (
    <List
      style={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        maxWidth: "100%",
      }}
    >
      {booking.allocations.map((allocation: Allocation) => (
        <ProductInstanceIdField record={allocation} source="product_instance" />
      ))}
    </List>
  );
};
