/* eslint-disable import/no-anonymous-default-export */
import Icon from "@material-ui/icons/ShoppingBasket";
import { FC } from "react";
import {
  Create,
  CreateProps,
  Datagrid,
  Edit,
  EditProps,
  Filter,
  List,
  ListProps,
  SaveButton,
  SelectInput,
  Show,
  ShowProps,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  Toolbar,
} from "react-admin";
import CrudBreadcrumb from "../components/CrudBreadcrumb";
import BusinessUnitInput from "../components/inputs/BusinessUnitInput";
import ProductTypeInput from "../components/inputs/ProductTypeInput";

const ResourceList: FC<ListProps> = (props) => (
  <List
    {...props}
    exporter={false}
    filters={<ListFilter />}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="business_unit.name" label="Business Unit" />
      <TextField source="description" />
      <TextField source="type" />
    </Datagrid>
  </List>
);

const ListFilter: FC<any> = (props) => (
  <Filter {...props}>
    <TextInput source="id" label="ID" />
    <TextInput source="search" label="Name and Description" />
    <SelectInput
      source="type"
      choices={[
        { id: "instrument", name: "instrument" },
        { id: "implant", name: "implant" },
        { id: "others", name: "others" },
      ]}
    />
    <BusinessUnitInput source="business_unit_id" />
  </Filter>
);

const ResourceShow: FC<ShowProps> = (props) => {
  return (
    <>
      <CrudBreadcrumb
        resourceName={Common.resourceName}
        resourceId={props.id}
        mode="show"
      />
      <Show {...props}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="business_unit.name" label="Business Unit" />
          <TextField source="type" />
          <TextField source="description" />
        </SimpleShowLayout>
      </Show>
    </>
  );
};

const ResourceEdit: FC<EditProps> = (props) => {
  const IssueEditToolbar: FC<any> = (props) => (
    <Toolbar {...props}>
      <SaveButton disabled={props.pristine} />
    </Toolbar>
  );
  return (
    <>
      <CrudBreadcrumb
        resourceName={Common.resourceName}
        resourceId={props.id}
        mode="edit"
      />
      <Edit {...props}>
        <SimpleForm redirect="show" toolbar={<IssueEditToolbar />}>
          <TextField source="id" />
          <TextInput source="name" />
          <BusinessUnitInput source="business_unit_id" />
          <ProductTypeInput source="type" />
          <TextInput source="description" />
        </SimpleForm>
      </Edit>
    </>
  );
};

const ResourceCreate: FC<CreateProps> = (props) => {
  return (
    <>
      <CrudBreadcrumb
        resourceName={Common.resourceName}
        resourceId={""}
        mode="create"
      />
      <Create {...props}>
        <SimpleForm>
          <TextInput source="name" />
          <SelectInput
            source="is_type"
            choices={[
              { id: "instrument", name: "instrument" },
              { id: "implant", name: "implant" },
              { id: "others", name: "others" },
            ]}
          />
          <TextInput source="description" />
        </SimpleForm>
      </Create>
    </>
  );
};

const Common = {
  resourceName: "products",
};

export default {
  name: Common.resourceName,
  icon: Icon,
  list: ResourceList,
  show: ResourceShow,
  create: ResourceCreate,
  edit: ResourceEdit,
};
