import React from "react";
import { Admin, Resource } from "react-admin";
import authProvider from "./authProvider";
import MyLayout from "./components/layout/Layout";
import dataProvider from "./dataProvider";
import admins from "./pages/admins";
import allocations from "./pages/allocations";
import bookings from "./pages/bookings";
import chats from "./pages/chats";
import consultants from "./pages/consultants";
import Dashboard from "./pages/dashboard";
import deliveries from "./pages/deliveries";
import drivers from "./pages/drivers";
import hospitalRooms from "./pages/hospitalRooms";
import hospitals from "./pages/hospitals";
import issues from "./pages/issues";
import locations from "./pages/locations";
import procedures from "./pages/procedures";
import productInstances from "./pages/productInstances";
import products from "./pages/products";
import settings from "./pages/settings";
import surgeons from "./pages/surgeons";
import templates from "./pages/templates";
import { theme } from "./theme";
import LoginPage from "./pages/auth/LoginPageCustom";
import inboxes from "./pages/inboxes";
import customRoutes from "./routes";
import { subscribeToPrivateChannel } from "./utils/echo";
import businessUnits from "./pages/BusinessUnit";
import colors from "./pages/colors";

subscribeToPrivateChannel();

const App = () => (
  <Admin
    dataProvider={dataProvider}
    layout={MyLayout}
    authProvider={authProvider}
    dashboard={Dashboard}
    theme={theme}
    loginPage={LoginPage}
    customRoutes={customRoutes}
  >
    <Resource {...admins} />
    <Resource {...consultants} />
    <Resource {...drivers} />
    <Resource {...products} />
    <Resource {...hospitals} />
    <Resource {...hospitalRooms} />
    <Resource {...surgeons} />
    <Resource {...procedures} />
    <Resource {...bookings} />
    <Resource {...locations} />
    <Resource {...settings} />
    <Resource {...templates} />
    <Resource {...issues} />
    <Resource {...productInstances} />
    <Resource {...deliveries} />
    <Resource {...allocations} />
    <Resource {...chats} />
    <Resource {...inboxes} />
    <Resource {...businessUnits} />
    <Resource {...colors} />
    <Resource name="reports" />
  </Admin>
);

export default App;
