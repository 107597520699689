import { Box, Button, ListItem, ListItemText } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { DateField, TextField } from "ra-ui-materialui";
import React from "react";
import {
  BookingIdField,
  ProductInstanceIdField,
} from "../../../components/fields";
import { Allocation, Booking, ProductInstance } from "../../../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      border: "1px solid #aaa",
      borderRadius: 5,
      padding: 10,
      marginBottom: "1rem",
    },
  })
);

interface ProductInstanceItemProps {
  instance: ProductInstance;
  allocations: Allocation[];
  onAllocate?: (instance: ProductInstance) => void;
  onRemoveAllocation?: (instance: ProductInstance) => void;
}
const ProductInstanceItem: React.FC<ProductInstanceItemProps> = (props) => {
  const classes = useStyles();
  const { instance, allocations, onAllocate } = props;
  const [isInstanceInAllocations, setIsInstanceInAllocations] =
    React.useState(false);

  /**
   * Check if a product instance is already in the allocations
   * @param instance
   */
  React.useEffect(() => {
    if (!instance) {
      return;
    }

    const res = allocations.find(
      (item) => item.product_instance_id === instance.id
    );
    setIsInstanceInAllocations(res ? true : false);
  }, [instance, allocations]);

  if (!instance) {
    return (
      <ListItem>
        <ListItemText primary="Loading..." />
      </ListItem>
    );
  }

  return (
    <Box className={classes.root} key={instance.id}>
      <Box display="flex" mb={2}>
        <Box flexGrow={1}>
          <ProductInstanceIdField record={instance} />
        </Box>
        {!isInstanceInAllocations && onAllocate && (
          <Box>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => onAllocate(instance)}
            >
              Allocate
            </Button>
          </Box>
        )}
      </Box>

      <Box mb={2} pb={2}>
        <Box>
          Current Location: {instance.location.name}
          <br />
          Product: {instance.product.name}
          <br />
          Type: {instance.product.type}
          <br />
        </Box>
      </Box>

      <Box display="flex" borderTop="1px solid #ddd" pt={2}>
        <Box flexGrow={1}>
          <strong>Previous Booking</strong>
          {instance.previous_bookings.length === 0 && <Box>-</Box>}
          {instance.previous_bookings.map((booking) => (
            <BookingCard booking={booking} />
          ))}
        </Box>

        <Box flexGrow={1}>
          <strong>Next Booking</strong>
          {instance.next_bookings.length === 0 && <Box>-</Box>}
          {instance.next_bookings.map((booking) => (
            <BookingCard booking={booking} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ProductInstanceItem;

/**
 * A component to show Available Product Sets
 * @param param0
 * @returns
 */
const BookingCard: React.FC<{ booking: Booking }> = ({ booking }) => {
  return (
    <Box my={1}>
      <Box>
        <BookingIdField record={booking} />
      </Box>
      <DateField record={booking} source="date" showTime label="Date" /> <br />
      <TextField record={booking} source="location.name" /> <br />
    </Box>
  );
};
