import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { get } from "lodash";
import { useMutation, useNotify } from "ra-core";
import React from "react";
import { Delivery } from "../../../types";
import { convertIsoToLocale } from "../../../utils/date";
import { deliveryActions } from "../util";

interface Props {
  record: Delivery;
}
const StartDeliveryDialog: React.FC<Props> = (props) => {
  const { record: delivery } = props;
  const [open, setOpen] = React.useState(false);
  const notify = useNotify();
  const pickedTime = new Date().toISOString();
  console.log(pickedTime);
  const pickedLocationId = get(delivery, "product_instance.location_id");

  const [handleAccept] = useMutation(
    {
      type: "update",
      resource: "deliveries",
      payload: {
        id: delivery.id ?? "",
        data: {
          action: deliveryActions.START,
          picked_time: pickedTime,
          picked_location_id: pickedLocationId,
        },
      },
    },
    {
      onSuccess: () => {
        notify(`Successfully start a Delivery Order.`, "info");
        setOpen(false);
      },
      onFailure: (error) => notify(`Error: ${error.message}`, "warning"),
    }
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button color="primary" onClick={handleClickOpen} size={"small"}>
        Start Delivery
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Start Delivery"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please confirm to start Delivery.
            <br />
            <pre>
              Delivery ID: {delivery.id}
              <br />
              Product:{" "}
              {`${delivery.product_instance.product.name} (${delivery.product_instance.name})`}
              <br />
              Pickup Location: {delivery.product_instance.location.name}
              <br />
              Destination Location: {delivery.target_location.name}
              <br />
              Deliver Before:{" "}
              {convertIsoToLocale(delivery.target_delivery_time)}
            </pre>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Back
          </Button>
          <Button onClick={handleAccept} color="primary" autoFocus>
            Confirm Start Delivery
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default StartDeliveryDialog;
