import Echo from "laravel-echo";
// @ts-ignore
// window.Pusher = require("pusher-js");
import Pusher from "pusher-js";

declare global {
  interface Window {
    Pusher: typeof Pusher;
    Echo: Echo;
  }
}

interface LaravelNotification {
  content: string;
  id: string;
  payload_id: number;
  payload_type: string;
  title: string;
  type: string;
}

window.Pusher = Pusher;
let echo: Echo | null = null;

/**
 * A function to subscribe user to the Private Socket Channel
 */
export const subscribeToPrivateChannel = () => {
  console.log('echo :>> ', echo);
  if (echo) {
    return;
  }

  checkNotificationPermission();

  const roles = localStorage.getItem("roles");
  const userId = localStorage.getItem("me");
  const token = localStorage.getItem("token");

  if (!roles || !userId || !token) {
    console.log("returning...");
    return;
  }
  console.log("connecting via echo...", token);

  const options = {
    broadcaster: "reverb",
    key: process.env.REACT_APP_PUSHER_KEY,
    wsHost: process.env.REACT_APP_PUSHER_HOST,
    wsPort: process.env.REACT_APP_PUSHER_PORT,
    wssPort: process.env.REACT_APP_PUSHER_PORT,
    disableStats: process.env.REACT_APP_PUSHER_DISABLE_STAT,
    enabledTransports: ["ws", "wss"],
    forceTLS: process.env.REACT_APP_PUSHER_HOST_SCHEME === "https" ? true : false,
    authEndpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT,
    auth: {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    },
  };
  echo = new Echo(options);
  echo.connector.pusher.connection.bind("connected", () => {
    console.log("Pusher is connected");
  });
  echo.connector.pusher.connection.bind("disconnected", () => {
    console.log("disconnected from pusher...");
  });

  // decide the role of the user. Then, subscribe to
  // respective channel in Laravel Socket via Echo
  let role = JSON.parse(roles).find((item: string) => item === "admin")
    ? "Admin"
    : "Consultant";

  echo
    .private(`App.Models.${role}.${userId}`)
    .notification((data: LaravelNotification) => {
      new Notification(data.title, { body: data.content });
    });

  if (role === "Admin") {
    echo.private('admins').listen("BookingUpdated", (e: any) => {
      console.log('e :>> ', e);
    });
  }
};

export const unsubscribeFromPrivateChannel = () => {
  if (echo) {
    echo.disconnect();
    echo = null;
  }
};

/**
 * A helper function to ensure that user always grant permission
 */
const checkNotificationPermission = () => {
  if (!("Notification" in window)) {
    alert("This browser does not support desktop notification");
  }

  // Let's check whether notification permissions have already been granted
  else if (Notification.permission === "granted") {
    // do nothing
  }
  // Otherwise, we need to ask the user for permission
  else {
    Notification.requestPermission().then(function (permission) {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        new Notification("Your notification will be shown here");
      }
    });
  }
};
