import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { Link as LinkRoute } from "react-router-dom";
import React from "react";

interface IProps {
  paths: {
    link: string;
    title: string;
  }[];
}
const Breadcrumb: React.FC<IProps> = ({ paths }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" style={{ padding: "10px 0" }}>
      {paths.map((item) => (
        <Link
          component={LinkRoute}
          color="inherit"
          to={{ pathname: `${item.link}` }}
          key={item.title}
        >
          {item.title}
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
