import { usePermissions } from "ra-core";

const useFinance = (): boolean => {
  const { permissions } = usePermissions();

  if (!permissions) return false;

  const isFinance = permissions.find((role: string) => role === "finance");
  return isFinance ? true : false;
};

export default useFinance;
