import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";

interface Props {
  title: string;
  status: JSX.Element;
  content: { label: string; value: string }[];
}

const MyCard: React.FC<Props> = (props) => {
  const { title, status, content } = props;

  return (
    <Box
      style={{
        minWidth: 275,
        maxWidth: 400,
        border: "1px solid #ddd",
        borderRadius: "7px",
        backgroundColor: "white",
        padding: "1rem",
      }}
    >
      <Grid
        container
        spacing={2}
        style={{ borderBottom: "1px solid #eee", backgroundColor: "#eee" }}
      >
        <Grid item xs={6}>
          <Typography
            style={{ textTransform: "uppercase", fontWeight: "bold" }}
          >
            {title}
          </Typography>
        </Grid>
        <Grid item style={{ textAlign: "right" }} xs={6}>
          {status}
        </Grid>
      </Grid>

      <Box style={{ fontSize: 14, lineHeight: "18px", marginTop: 12 }}>
        {content.map((item, index) => (
          <MyCardItem key={index} label={item.label} value={item.value} />
        ))}
      </Box>
    </Box>
  );
};

const MyCardItem: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => (
  <Box>
    <strong>{label.toUpperCase()}</strong>: {value}
  </Box>
);

export default MyCard;
