import React from "react";
import { Allocation } from "../../../types";
import { AllocationActions } from "../util";
import ActionProps from "./AllocationActionProps";
import ConfirmationButton from "./ConfirmationButton";

const CancelButton: React.FC<ActionProps<Allocation>> = (props) => {
  const { data: allocation } = props;

  const dialogContent = `The assignment will be cancelled. The corresponding Delivery ID ${
    allocation.delivery_id ?? ""
  } will also be cancelled. Confirm?`;

  return (
    <ConfirmationButton
      data={allocation}
      action={AllocationActions.UNASSIGN}
      buttonText={`Cancel`}
      dialogTitle={`Cancel Allocation`}
      dialogContent={dialogContent}
      confirmationButtonText={`Confirm`}
    />
  );
};

export default CancelButton;
