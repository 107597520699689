import React, { useEffect } from "react";
import { SelectInput, useQueryWithStore } from "react-admin";
import { HospitalRoom } from "../../types";

interface Props {
  source: string;
}
const HospitalRoomInput: React.FC<Props> = (props) => {
  const [options, setOptions] = React.useState([]);
  const { data } = useQueryWithStore({
    type: "getList",
    resource: "hospital-rooms",
    payload: {
      sort: { field: "name", order: "asc" },
      pagination: { page: 1, perPage: 1000 },
    },
  });

  useEffect(() => console.log("data :>> ", data), [data]);
  useEffect(() => console.log("props :>> ", props), [props]);

  /**
   * Populate the options
   */
  React.useEffect(() => {
    if (!data) return;

    const mapped = data
      .map((hospitalRoom: HospitalRoom) => ({
        id: hospitalRoom.id,
        name: `${hospitalRoom.hospital.name} (${hospitalRoom.name})`,
      }))
      .sort((a: { name: string }, b: { name: string }) =>
        a.name <= b.name ? -1 : 1
      );
    setOptions(mapped);
  }, [data]);

  return <SelectInput {...props} choices={options} />;
};

export default HospitalRoomInput;
