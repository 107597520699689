import { get } from "lodash";
import React from "react";
import { Media } from "../types";
import { FieldProps } from "./fields/types";
import Box from "@material-ui/core/Box";
import Thumbnail from "./Thumbnail";

const MediaListField: React.FC<FieldProps> = (props) => {
  const { record, source } = props;
  if (!record) return <></>;

  const medias: Media[] = get(record, source);

  return (
    <div>
      <Box style={{ display: "flex", flexFlow: "wrap" }}>
        {medias.map((media, counter) => (
          <Thumbnail url={media.url} />
        ))}
      </Box>
    </div>
  );
};
MediaListField.defaultProps = {
  addLabel: true,
};

export default MediaListField;
