import { format, formatISO9075, parse, parseISO } from "date-fns";

const jsFormat = "yyyy-MM-dd'T'HH:mm";
const sqlFormat = "yyyy-MM-dd HH:mm:ss";
const sqlDateFormat = "yyyy-MM-dd";
const localeDateFormat = "dd/MM/yyyy HH:mm";

/**
 * Convert JS time string to SQL time string
 * For example, from "2020-01-01T12:22" to "2020-01-01 12:22:00"
 * @param jsString
 */
export const convertJsToSql = (jsString: string) =>
  format(parse(jsString, jsFormat, new Date()), sqlFormat);

/**
 * Convert SQL time string to format used by TextInput
 * For example, from "2020-01-01 12:22:00" to "2020-01-01T12:22"
 * @param sqlString
 */
export const convertSqlToJs = (sqlString: string | null) => {
  if (!sqlString) {
    return format(new Date(), jsFormat);
  } else {
    return format(parse(sqlString, sqlFormat, new Date()), jsFormat);
  }
};

/**
 * Convert SQL date string to Locale date format
 * @param sqlString
 */
export const convertSqlDateToLocale = (sqlString: string) =>
  format(parse(sqlString, sqlDateFormat, new Date()), localeDateFormat);

export const convertIsoToLocale = (isoString: string | null) => {
  if (isoString) {
    return format(parseISO(isoString), localeDateFormat);
  }
};

/**
 * A helper function to convert Javascript Date to mySQL Date.
 * E.g. mysql date: 2020-01-31.
 *
 * @param date date to be converted
 * @returns
 */
export const convertJsDateToMySqlDate = (date: Date) =>
  formatISO9075(date, { representation: "date" });

/**
 * Convert ISO date string to "date month year" and "hour minute"
 * @param isoString
 * @returns formatted date and time
 */
export const convertIsoToDateTime = (isoString: string) => {
  const date = parseISO(isoString);
  
  const formattedDate = format(date, 'dd MMMM yyyy'); // e.g. 22 october 2024
  const formattedTime = format(date, 'HH:mm'); // e.g. 01:34
  
  return {
    date: formattedDate,
    time: formattedTime,
  };
};