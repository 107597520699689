import Icon from "@material-ui/icons/BugReport";
import { capitalize } from "lodash";
import { FC } from "react";
import {
  AutocompleteInput,
  Create,
  CreateProps,
  Datagrid,
  DateField,
  DateInput,
  Edit,
  EditProps,
  Filter,
  FormDataConsumer,
  List,
  ListProps,
  ReferenceField,
  ReferenceInput,
  SaveButton,
  SelectInput,
  Show,
  ShowProps,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  Toolbar,
} from "react-admin";
import CrudBreadcrumb from "../components/CrudBreadcrumb";
import ProductInstanceField from "../components/fields/ProductInstanceIdField";
import StatusField from "../components/StatusField";
import TitleBreadcrumb from "../components/TitleBreadcrumb";

const resourceName = "issues";

const ResourceList: FC<ListProps> = (props) => (
  <List
    {...props}
    exporter={false}
    filters={<ListFilter />}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="show">
      <TextField source="id" />
      <DateField source="created_at" showTime={true} />
      <ProductInstanceField source="product_instance" />

      <ReferenceField
        source="reporter_id"
        reference="consultants"
        label="Reported By"
      >
        <TextField source="full_name" />
      </ReferenceField>
      <TextField source="description" />
      <StatusField source="status" />
    </Datagrid>
  </List>
);

const ListFilter: FC<any> = (props) => (
  <Filter {...props}>
    <TextInput source="id" label="ID" />
    <DateInput source="date" />
    <TextInput source="product_instance_id" label="Set" />
    {/* <ReferenceInput
      label="Set"
      source="product_instance"
      reference="product-instances"
      allowEmpty
    >
      <AutocompleteInput source="name" />
    </ReferenceInput> */}
    <ReferenceInput
      label="Report by"
      source="reporter_id"
      reference="consultants"
      allowEmpty
    >
      <AutocompleteInput optionText="full_name" />
    </ReferenceInput>
  </Filter>
);

const ResourceShow: FC<ShowProps> = (props) => {
  const breadcrumbPaths = [
    { link: `/${resourceName}`, title: `${capitalize(resourceName)}` },
    { link: `/${resourceName}/${props.id}/show`, title: `${props.id}` },
  ];

  return (
    <>
      <TitleBreadcrumb paths={breadcrumbPaths} />
      <Show {...props}>
        <SimpleShowLayout>
          <TextField source="id" />
          <DateField source="updated_at" showTime={true} />
          <ProductInstanceField source="product_instance" />
          <ReferenceField
            source="reporter_id"
            reference="consultants"
            label="Reported By"
          >
            <TextField source="full_name" />
          </ReferenceField>
          <TextField source="status" />
          <TextField source="description" />
          <TextField source="warehouse_note" />
          <TextField source="closing_remarks" />
        </SimpleShowLayout>
      </Show>
    </>
  );
};

const IssueEditToolbar: FC<any> = (props) => (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
  </Toolbar>
);

const ResourceEdit: FC<EditProps> = (props) => (
  <Edit {...props}>
    <SimpleForm toolbar={<IssueEditToolbar />}>
      <TextField source="id" />
      <DateField source="updated_at" showTime={true} />
      <ReferenceInput
        label="Product Instance"
        source="product_instance_id"
        reference="product-instances"
        sort={{ field: "name", order: "asc" }}
        perPage={1000}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="reporter_id" reference="consultants">
        <SelectInput optionText="full_name" />
      </ReferenceInput>
      <SelectInput
        source="status"
        choices={[
          { id: IssueStatus.OPEN, name: "Open" },
          { id: IssueStatus.RESOLVED, name: "Resolved" },
        ]}
      />
      <TextInput source="description" />
      <TextInput source="warehouse_note" />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.status === IssueStatus.RESOLVED && (
            <TextInput source="closing_remarks" />
          )
        }
      </FormDataConsumer>
    </SimpleForm>
  </Edit>
);

const ResourceCreate: FC<CreateProps> = (props) => {
  return (
    <>
      <CrudBreadcrumb
        resourceName={Common.resourceName}
        resourceId={""}
        mode="create"
      />
      <Create {...props}>
        <SimpleForm>
          <ReferenceInput
            label="Product Instance"
            source="product_instance_id"
            reference="product-instances"
            sort={{ field: "name", order: "asc" }}
            perPage={1000}
          >
            <AutocompleteInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput source="reporter_id" reference="consultants">
            <SelectInput optionText="full_name" />
          </ReferenceInput>
          <TextInput source="description" />
          <TextInput source="warehouse_note" />
        </SimpleForm>
      </Create>
    </>
  );
};

const Common = {
  resourceName: "issues",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  name: resourceName,
  icon: Icon,
  list: ResourceList,
  show: ResourceShow,
  edit: ResourceEdit,
  create: ResourceCreate,
};

export const IssueStatus = {
  OPEN: "open",
  RESOLVED: "resolved",
};
