import { formatISO9075 } from "date-fns/esm";
import { get } from "lodash";
import React from "react";
import { Booking } from "../../types";
import { BookingIdField } from "../fields";
import { FieldProps } from "../fields/types";
import MyCard from "./MyCard";

const BookingCard: React.FC<FieldProps> = (props) => {
  const { record, source } = props;
  const booking: Booking = source === "id" ? record : get(record, source);

  if (!booking) return <></>;

  return (
    <MyCard
      title="Booking"
      status={<BookingIdField record={booking} />}
      content={[
        { label: "date", value: formatISO9075(new Date(booking.date)) },
        { label: "Venue", value: booking.location?.name ?? "" },
        { label: "Surgeon", value: booking.surgeon?.full_name ?? "" },
        { label: "Consultant", value: booking.consultant?.full_name ?? "" },
        { label: "Procedure", value: booking.procedure?.name ?? "" },
      ]}
    />
  );
};

BookingCard.defaultProps = {
  label: "Booking",
  addLabel: true,
};

export default BookingCard;
