import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { get } from "lodash";
import { useMutation, useNotify } from "ra-core";
import React from "react";
import { Allocation } from "../../../types";
import ActionProps from "./AllocationActionProps";

interface ConfirmationButtonProps<T> extends ActionProps<T> {
  action: string;
  buttonText: string;
  dialogTitle: string;
  dialogContent: string;
  confirmationButtonText: string;
}

const ConfirmationButton: React.FC<ConfirmationButtonProps<Allocation>> = (
  props
) => {
  const notify = useNotify();
  const [open, setOpen] = React.useState(false);
  const {
    data: allocation,
    action,
    buttonText,
    dialogTitle,
    dialogContent,
    confirmationButtonText,
  } = props;
  const { product_instance } = allocation;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /**
   * When user clicks the OK Button
   */
  const [handleOk] = useMutation(
    {
      type: "update",
      resource: "allocations",
      payload: {
        id: allocation.id,
        data: {
          action,
        },
      },
    },
    {
      onSuccess: ({ data }: { data: Allocation }) => {
        if (data.product_instance) {
          notify(`Update is successful`, "success");
        }

        setOpen(false);
      },
      onFailure: (e) => notify(get(e, "message"), "error"),
    }
  );

  const handleCancel = () => {
    setOpen(false);
  };

  if (!product_instance) {
    return <></>;
  }

  return (
    <Box>
      <Button variant="contained" onClick={handleClickOpen}>
        {buttonText}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        disableEscapeKeyDown
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogContent}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleOk} color="primary">
            {confirmationButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ConfirmationButton;
