import React from "react";
import { DeliveryChip } from "../Chip";
import IdField from "./IdField";
import { FieldProps } from "./types";

interface Props extends Omit<FieldProps, "source"> {
  source?: string;
}
const DeliveryIdField: React.FC<Props> = (props) => {
  return <IdField {...props}>{React.createElement(DeliveryChip)}</IdField>;
};

DeliveryIdField.defaultProps = {
  label: "Delivery",
  addLabel: true,
};

export default DeliveryIdField;
